import { CheckIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, UseFormReturn } from "react-hook-form";
import { CsvModel } from "../../../../components/ExportCsv";
import { Form, FormInput } from "../../../../components/FormComponents";
import Modal from "../../../../components/Modal";
import { classNames, isNumeric, isValidPhoneNumber } from "../../../../utils";
import { useState } from "react";
import * as yup from "yup";
import { useConfirm } from "../../../../hooks/useConfirm";
import {
  globalConfigCollectionsName,
  feesConfigId,
  defaultAmount,
} from "../../../../config";
import { db, functions } from "../../../../firebase";
import Papa from "papaparse";
import DataTable, { TableColumn } from "react-data-table-component";
import React from "react";
import toast from "react-hot-toast";
import { useRouter } from "../../../../hooks/useRouter";

export enum PaymentFormType {
  BYFORMPAYMENT = "BYFORMPAYMENT",
  BYCSVPAYMENT = "BYCSVPAYMENT",
}

interface FormFields {
  contactFile: any;
}

const excelSMSFileHeaders = [
  { label: "Nom", key: "name" },
  { label: "Numero", key: "number" },
  { label: "Montant", key: "amount" },
  { label: "CNIB", key: "cnib" },
  { label: "DATE_DE_DELIVRANCE", key: "dateOfIssue" },
  { label: "NIP", key: "nip" },
  { label: "DATE_DE_DEBUT_DU_CONTRAT", key: "functionStartDate" },
  { label: "DATE_DE_FIN_DU_CONTRAT", key: "functionEndDate" },
  { label: "ZONE", key: "zone" },
  { label: "REGION", key: "region" },
  { label: "MOTIF", key: "motif" },
];

const csvModelData = [
  {
    number: "+22670180847",
    name: "Abdoul B",
    amount: "100",
    cnib: "B123456",
    dateOfIssue: "12/12/2021",
    nip: "123456",
    functionStartDate: "12/12/2021",
    functionEndDate: "12/12/2021",
    zone: "Ouaga",
    region: "Centre",
    motif: "Raison de paiement",
  },
  {
    number: "+22670081874",
    name: "Dicko Docko",
    amount: "100",
    cnib: "B123456",
    dateOfIssue: "12/12/2021",
    nip: "123456",
    functionStartDate: "12/12/2021",
    functionEndDate: "12/12/2021",
    zone: "Ouaga",
    region: "Centre",
    motif: "Raison de paiement",
  },
  {
    number: "+22655992656",
    name: "Omar Diallo",
    amount: "100",
    dateOfIssue: "12/12/2021",
    nip: "123456",
    functionStartDate: "12/12/2021",
    functionEndDate: "12/12/2021",
    zone: "Ouaga",
    region: "Centre",
    motif: "Raison de paiement",
  },
  {
    number: "+22655569926",
    name: "Omar Yero",
    amount: "100",
    dateOfIssue: "12/12/2021",
    nip: "123456",
    functionStartDate: "12/12/2021",
    functionEndDate: "12/12/2021",
    zone: "Ouaga",
    region: "Centre",
    motif: "Raison de paiement",
  },
  {
    number: "+22655992856",
    name: "SImo Kampiti",
    amount: "100",
    dateOfIssue: "12/12/2021",
    nip: "123456",
    functionStartDate: "12/12/2021",
    functionEndDate: "12/12/2021",
    zone: "Ouaga",
    region: "Centre",
    motif: "Raison de paiement",
  },
];

const steps = [
  {
    step: 1,
    title: "Uploader un fichier de contact",
  },
  {
    step: 2,
    title: "Vérifier et ajuster les contacts",
  },
  // {
  //   step: 3,
  //   title: 'Configurer le message',
  // },
  {
    step: 3,
    title: "Voir le résumé et envoyer",
  },
];

interface DataRow {
  number: string;
  name: string;
  amount: string;
  cnib: string;
  dateOfIssue: string;
  nip: string;
  functionStartDate: string;
  functionEndDate: string;
  zone: string;
  region: string;
  motif: string;
}

const columns: TableColumn<DataRow>[] = [
  {
    name: "Nom/Prénoms",
    selector: (row) => row.name,
  },
  {
    name: "Téléphone",
    selector: (row) => row.number,
  },
  {
    name: "Montant",
    selector: (row) => row.amount,
  },
  {
    name: "CNIB",
    selector: (row) => row.cnib,
  },
  {
    name: "DATE_DE_DELIVRANCE",
    selector: (row) => row.dateOfIssue,
  },
  {
    name: "NIP",
    selector: (row) => row.nip,
  },
  {
    name: "DATE_DE_DEBUT_DU_CONTRAT",
    selector: (row) => row.functionStartDate,
  },
  {
    name: "DATE_DE_FIN_DU_CONTRAT",
    selector: (row) => row.functionEndDate,
  },
  {
    name: "ZONE",
    selector: (row) => row.zone,
  },
  {
    name: "REGION",
    selector: (row) => row.region,
  },
  {
    name: "MOTIF",
    selector: (row) => row.motif,
  },
];

const selectProps = {
  indeterminate: (isIndeterminate: any) => isIndeterminate.toString(),
};

const contextActions = (deleteHandler: any) => (
  <TrashIcon className="w-6 h-6 text-red-500" onClick={deleteHandler} />
);

const AdminBulkPayment = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [validateStep, setValidateStep] = useState<string[]>([""]);
  const [formReturn, setFormReturn] = useState<UseFormReturn<FormFields>>();
  const [fileName, setFileName] = useState("");
  const [showAddContact, setShowAddContact] = useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const [selectedRows, setSelectedRows] = React.useState<DataRow[]>();

  const [simularNumber, setSimularNumber] = useState<any[]>([]);
  const [notDefineNumber, setNotDefineNumber] = useState<any[]>([]);
  const [notFormatedNumber, setNotFormatedNumber] = useState<any[]>([]);
  const [notDefineAmount, setNotDefineAmount] = useState<any[]>([]);
  const [notFormatedAmount, setNotFormatedAmount] = useState<any[]>([]);

  const [fileData, setFileData] = useState<any[]>([]);
  const [filter, setFilter] = useState<any[]>([]);
  const [CSVTotalAmount, setCSVTotalAmount] = useState(0);
  const { isConfirmModal } = useConfirm();

  const sankmoneyUploadBulkpaymentToBucketFn = functions.httpsCallable(
    "sankmoneyUploadBulkpaymentToBucketFn"
  );

  const handleReadCSV = (event: any) => {
    setFileName(event.target.files[0]?.name);
    let sankMoneyContact: any[] = [];
    let notDefineContactPhoneNumber: any[] = [];
    let notDefineContactAmount: any[] = [];
    let notFormatContactAmount: any[] = [];
    let notFormatContactNumber: any[] = [];

    setFileName(event.target.files[0]?.name);
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {
        const data = results.data.map((data: any) => {
          return {
            name: data.Nom,
            number: data.Numero,
            amount: data.Montant,
            cnib: data.CNIB,
            dateOfIssue: data.DATE_DE_DELIVRANCE,
            nip: data.NIP,
            functionStartDate: data.DATE_DE_DEBUT_DU_CONTRAT,
            functionEndDate: data.DATE_DE_FIN_DU_CONTRAT,
            zone: data.ZONE,
            region: data.REGION,
            motif: data.MOTIF,
          };
        });
        for (const el of data as any) {
          const phoneNumber = el.number
            ? el.number.startsWith("+")
              ? el.number
              : `+${el.number}`
            : "";
          if (!el.amount) {
            notDefineContactAmount.push({
              name: el.name,
              number: phoneNumber,
              amount: "",
              cnib: el.cnib,
              dateOfIssue: el.dateOfIssue,
              nip: el.nip,
              functionStartDate: el.functionStartDate,
              functionEndDate: el.functionEndDate,
              zone: el.zone,
              region: el.region,
              motif: el.motif,
            });
          }
          if (!el.number) {
            notDefineContactPhoneNumber.push({
              name: el.name,
              number: "",
              amount: el.amount,
              cnib: el.cnib,
              dateOfIssue: el.dateOfIssue,
              nip: el.nip,
              functionStartDate: el.functionStartDate,
              functionEndDate: el.functionEndDate,
              zone: el.zone,
              region: el.region,
              motif: el.motif,
            });
          }
          if (el.amount) {
            if (!isNumeric(el.amount)) {
              notFormatContactAmount.push({
                name: el.name,
                number: phoneNumber,
                amount: el.amount,
                cnib: el.cnib,
                dateOfIssue: el.dateOfIssue,
                nip: el.nip,
                functionStartDate: el.functionStartDate,
                functionEndDate: el.functionEndDate,
                zone: el.zone,
                region: el.region,
                motif: el.motif,
              });
            }
            if (el.amount < defaultAmount) {
              notFormatContactAmount.push({
                name: el.name,
                number: phoneNumber,
                amount: el.amount,
                cnib: el.cnib,
                dateOfIssue: el.dateOfIssue,
                nip: el.nip,
                functionStartDate: el.functionStartDate,
                functionEndDate: el.functionEndDate,
                zone: el.zone,
                region: el.region,
                motif: el.motif,
              });
            }
          }

          if (el.number) {
            const phoneNumber = el.number
              ? el.number.startsWith("+")
                ? el.number
                : `+${el.number}`
              : "";
            if (!isValidPhoneNumber(phoneNumber)) {
              notFormatContactNumber.push({
                name: el.name,
                number: el.number,
                amount: el.amount,
                cnib: el.cnib,
                dateOfIssue: el.dateOfIssue,
                nip: el.nip,
                functionStartDate: el.functionStartDate,
                functionEndDate: el.functionEndDate,
                zone: el.zone,
                region: el.region,
                motif: el.motif,
              });
            }
          }

          sankMoneyContact.push({
            name: el.name,
            number: phoneNumber,
            amount: parseInt(el.amount),
            cnib: el.cnib,
            dateOfIssue: el.dateOfIssue,
            nip: el.nip,
            functionStartDate: el.functionStartDate,
            functionEndDate: el.functionEndDate,
            zone: el.zone,
            region: el.region,
            motif: el.motif,
          });
        }

        setSimularNumber(findDuplicateContacts(sankMoneyContact));
        setNotDefineNumber(notDefineContactPhoneNumber);
        setNotDefineAmount(notDefineContactAmount);
        setNotFormatedAmount(notFormatContactAmount);
        setNotFormatedNumber(notFormatContactNumber);

        // Retrieve duplicated phone number
        const duplicatedItems = findDuplicateContacts(sankMoneyContact);
        // Filter undefined phone number
        const removeNotDefineContactsPhoneNumbers = sankMoneyContact.filter(
          (vals) => vals.number !== ""
        );
        // Filter duplicated phone number
        const removeDuplicatedContacts =
          removeNotDefineContactsPhoneNumbers.filter(
            (vals) =>
              !duplicatedItems.some((item) => vals.number === item.number)
          );
        // Filter undefined amount
        const removeNotDefineContactsAmount = removeDuplicatedContacts.filter(
          (vals) => !notDefineContactAmount.includes(vals.number)
        );
        // Filter unformated amount
        const removeNotFormatContactAmount =
          removeNotDefineContactsAmount.filter((vals) =>
            isNumeric(vals.amount)
          );
        // Filter unformated amount
        const removeNotFormatContactUnderDefaultAmount =
          removeNotFormatContactAmount.filter(
            (vals) => vals.amount >= defaultAmount
          );
        // Filter unformated phone number
        const removeNotFormatContactNumber =
          removeNotFormatContactUnderDefaultAmount.filter((vals) =>
            isValidPhoneNumber(vals.number)
          );

        const amount = removeNotFormatContactNumber.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue.amount),
          0
        );
        setCSVTotalAmount(amount);
        setFileData(removeNotFormatContactNumber);
        setFilter(removeNotFormatContactNumber);
      },
    });
  };

  const findDuplicateContacts = (contacts: any) => {
    const numberMap: any = {};
    const duplicates = [];

    contacts.forEach((contact: any) => {
      const number = contact.number;
      if (numberMap[number]) {
        numberMap[number].push(contact);
      } else {
        numberMap[number] = [contact];
      }
    });

    for (const number in numberMap) {
      if (numberMap[number].length > 1) {
        duplicates.push(...numberMap[number]);
      }
    }

    return duplicates;
  };

  const handleAddContact = (data: DataRow) => {
    setFileData((pre: any) => [
      {
        number: `${data.number}`,
        name: data.name,
        amount: data.amount,
      },
      ...pre,
    ]);
    setShowAddContact(false);
  };

  const handleFilter = (value: any) => {
    const data = fileData.filter((item) =>
      item.number.includes(value.target.value)
    );
    setFilter(data);
  };

  const onSubmitPaymentFile = async (data: any) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir uploader le fichier.",
      "Oui uploader",
      true
    );
    if (isConfirm) {
      const id = toast.loading("Upload en cours...");
      const vals = {
        companyName: data.companyName,
        paymentData: fileData,
      };
      sankmoneyUploadBulkpaymentToBucketFn(vals)
        .then(() => {
          toast.success("Uploader avec succès.", {
            id,
          });
          setValidateStep([""]);
          setCurrentStep(1);
          setFileData([]);
          setCSVTotalAmount(0);
          setFileName("");
          setSimularNumber([]);
          setNotDefineNumber([]);
          setNotDefineAmount([]);
          setNotFormatedAmount([]);
          setNotFormatedNumber([]);
        })
        .catch((error) => {
          toast.error(`${error.message}`, {
            id,
          });
        });
    }
  };

  return (
    <>
      <div className="py-2 border-b border-gray-200 dark:border-slate-700">
        <h2 className="text-lg font-semibold leading-6 text-gray-900 dark:text-gray-300">
          Paiement en masse
        </h2>
        <p className="mt-1 text-base text-gray-500">
          Déposé le fichier pour le paiement en masse
        </p>
      </div>
      <nav aria-label="Progress">
        <ol className="my-4 border border-gray-300 divide-y divide-gray-300 rounded-md dark:border-slate-700 dark:divide-slate-700 md:flex md:divide-y-0">
          {steps.map((element) => {
            return (
              <li key={element.step} className="relative md:flex md:flex-1">
                {element.step === currentStep ? (
                  <div className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-red-600 rounded-full">
                      <span className="text-red-600">{element.step}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-red-600">
                      {element.title}
                    </span>
                  </div>
                ) : validateStep.includes(`${element.step}`) ? (
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-red-600 rounded-full group-hover:bg-red-800">
                      <CheckIcon
                        className="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900 dark:text-gray-400">
                      {element.title}
                    </span>
                  </span>
                ) : (
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-gray-300 rounded-full dark:border-slate-700 group-hover:border-gray-400">
                      <span className="text-gray-500 group-hover:text-gray-900">
                        {element.step}
                      </span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {element.title}
                    </span>
                  </span>
                )}
                {element.step !== steps.length && (
                  <div
                    className="absolute top-0 right-0 hidden w-5 h-full md:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="w-full h-full text-gray-300 dark:text-slate-700"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
      {currentStep === 1 && (
        <Form<FormFields>
          downloadCsvOrXlsxFileBtn={
            <CsvModel
              csvData={csvModelData}
              headers={excelSMSFileHeaders}
              fileName={`contacts-${new Date().toLocaleDateString()}`}
              text={`Télécharger le model CSV`}
              className="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-red-700 transition-all duration-200 bg-white border border-red-700 rounded-md shadow-sm gap-x-2 dark:bg-slate-800 hover:border-red-900 hover:text-red-900 dark:hover:text-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            />
          }
          maximizeFormWidh={false}
          maximizeFormActionBtnWidh={false}
          formReturnAware={setFormReturn}
          submitButtonLabel="Continuer"
          isSubmitBtnDisabled={
            fileData.length > 0 &&
            simularNumber.length <= 0 &&
            notDefineNumber.length <= 0 &&
            notFormatedNumber.length <= 0 &&
            notDefineAmount.length <= 0 &&
            notFormatedAmount.length <= 0
              ? false
              : true
          }
          onSubmit={() => {
            setCurrentStep(currentStep + 1);
            setValidateStep([...validateStep, "1"]);
          }}
          form={{
            resolver: yupResolver(
              yup.object().shape({
                contactFile: yup
                  .mixed()
                  .required("Vous devez ajouter un fichier"),
              })
            ),
          }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2">
            <Controller
              control={formReturn?.control}
              name="contactFile"
              render={({ field, fieldState }) => (
                <div className="mt-3">
                  <div className="items-center justify-between flex-1 max-w-lg mx-auto">
                    <div className="flex justify-between">
                      <p className="my-2 text-base font-semibold leading-6 text-gray-900 dark:text-gray-300">
                        Importez votre fichier de contact
                      </p>
                    </div>
                    <div className="flex justify-center w-full">
                      <label
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer h-96 bg-gray-50 dark:hover:bg-slate-700 dark:bg-gray-800 hover:bg-gray-100 dark:border-slate-700 dark:hover:border-slate-700"
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          {fileName ? (
                            <div className="text-sm text-gray-500 dark:text-gray-400">
                              {fileName}{" "}
                            </div>
                          ) : (
                            <>
                              <svg
                                aria-hidden="true"
                                className="w-10 h-10 mb-3 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                ></path>
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">
                                Uploader un fichier CSV (CSV)
                              </p>
                            </>
                          )}
                        </div>
                        <input
                          name="contactFile"
                          id="dropzone-file"
                          type="file"
                          accept=".csv"
                          className="hidden"
                          onChange={(event) => {
                            handleReadCSV(event);
                            formReturn?.setValue("contactFile", null);
                            field.onChange(event);
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  {fieldState.error && (
                    <p
                      className={classNames(
                        fieldState.error
                          ? "opacity-100 translate-y-3 mb-0"
                          : "",
                        "text-sm text-red-600 opacity-0 transform transition-all duration-700 -mt-3"
                      )}
                    >
                      {fieldState.error && fieldState.error.message}
                    </p>
                  )}
                </div>
              )}
            />
            <div className="w-full max-md:w-full">
              <div className="flex items-center justify-center w-full px-6 mt-10 bg-white md:mt-2">
                <div className="w-full border-l-2 border-dashed">
                  <div className="relative w-full">
                    {simularNumber.length > 0 ? (
                      <XMarkIcon
                        className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-red-500 bg-red-500 rounded-full text-white"
                        aria-hidden="true"
                      />
                    ) : (
                      <CheckIcon
                        className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-green-500 bg-green-500 rounded-full text-white"
                        aria-hidden="true"
                      />
                    )}
                    <div className="ml-6">
                      <h4
                        className={classNames(
                          simularNumber.length > 0
                            ? "text-red-500"
                            : "text-blue-500",
                          "font-bold"
                        )}
                      >
                        Numéro de téléphone similaire.
                      </h4>
                      <p className="max-w-screen-sm mt-2 text-sm text-gray-500">
                        Cette rubrique vérifie les utilisateurs qui ont le même
                        numéro de téléphone.
                      </p>
                      <span className="block mt-1 text-sm font-semibold text-red-300 hover:text-red-500">
                        {simularNumber.length > 0 && (
                          <span
                            className={classNames(
                              simularNumber.length > 0
                                ? "cursor-pointer"
                                : "cursor-not-allowed",
                              "p-1 border rounded-md "
                            )}
                          >
                            <CsvModel
                              csvData={simularNumber}
                              headers={excelSMSFileHeaders}
                              fileName={` Numero de telephone similaire.`}
                              text={`Voir le fichier`}
                              className={classNames(
                                simularNumber.length > 0
                                  ? "cursor-pointer"
                                  : "cursor-not-allowed",
                                ""
                              )}
                            />
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="relative w-full mt-1 md:mt-4">
                    {notDefineNumber.length > 0 ? (
                      <XMarkIcon
                        className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-red-500 bg-red-500 rounded-full text-white"
                        aria-hidden="true"
                      />
                    ) : (
                      <CheckIcon
                        className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-green-500 bg-green-500 rounded-full text-white"
                        aria-hidden="true"
                      />
                    )}
                    <div className="ml-6">
                      <h4
                        className={classNames(
                          notDefineNumber.length > 0
                            ? "text-red-500"
                            : "text-blue-500",
                          "font-bold"
                        )}
                      >
                        Numéro de téléphone non définie
                      </h4>
                      <p className="max-w-screen-sm mt-2 text-sm text-gray-500">
                        Cette rubrique vérifie les utilisateurs qui n'ont pas de
                        numéro de téléphone définie.
                      </p>
                      <span className="block mt-1 text-sm font-semibold text-red-300 hover:text-red-500">
                        {notDefineNumber.length > 0 && (
                          <span
                            className={classNames(
                              notDefineNumber.length > 0
                                ? "cursor-pointer"
                                : "cursor-not-allowed",
                              "p-1 border rounded-md "
                            )}
                          >
                            <CsvModel
                              csvData={notDefineNumber}
                              headers={excelSMSFileHeaders}
                              fileName={`Numero de telephone non definie`}
                              text={`Voir le fichier`}
                              className={classNames(
                                notDefineNumber.length > 0
                                  ? "cursor-pointer"
                                  : "cursor-not-allowed",
                                ""
                              )}
                            />
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="relative w-full mt-1 md:mt-4">
                    {notFormatedNumber.length > 0 ? (
                      <XMarkIcon
                        className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-red-500 bg-red-500 rounded-full text-white"
                        aria-hidden="true"
                      />
                    ) : (
                      <CheckIcon
                        className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-green-500 bg-green-500 rounded-full text-white"
                        aria-hidden="true"
                      />
                    )}
                    <div className="ml-6">
                      <h4
                        className={classNames(
                          notFormatedNumber.length > 0
                            ? "text-red-500"
                            : "text-blue-500",
                          "font-bold"
                        )}
                      >
                        Numéro de téléphone mal formaté
                      </h4>
                      <p className="max-w-screen-sm mt-2 text-sm text-gray-500">
                        Cette rubrique vérifie les utilisateurs qui ont pas de
                        numéro de téléphone mal formaté.
                      </p>
                      <span className="block mt-1 text-sm font-semibold text-red-300 hover:text-red-500">
                        {notFormatedNumber.length > 0 && (
                          <span
                            className={classNames(
                              notFormatedNumber.length > 0
                                ? "cursor-pointer"
                                : "cursor-not-allowed",
                              "p-1 border rounded-md "
                            )}
                          >
                            <CsvModel
                              csvData={notFormatedNumber}
                              headers={excelSMSFileHeaders}
                              fileName={`Numero de telephone mal formate`}
                              text={`Voir le fichier`}
                              className={classNames(
                                notFormatedNumber.length > 0
                                  ? "cursor-pointer"
                                  : "cursor-not-allowed",
                                ""
                              )}
                            />
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="relative w-full mt-1 md:mt-4">
                    {notDefineAmount.length > 0 ? (
                      <XMarkIcon
                        className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-red-500 bg-red-500 rounded-full text-white"
                        aria-hidden="true"
                      />
                    ) : (
                      <CheckIcon
                        className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-green-500 bg-green-500 rounded-full text-white"
                        aria-hidden="true"
                      />
                    )}
                    <div className="ml-6">
                      <h4
                        className={classNames(
                          notDefineAmount.length > 0
                            ? "text-red-500"
                            : "text-blue-500",
                          "font-bold"
                        )}
                      >
                        Montant non definie
                      </h4>
                      <p className="max-w-screen-sm mt-2 text-sm text-gray-500">
                        Cette rubrique vérifie les utilisateurs qui n'ont pas de
                        montant définie.
                      </p>
                      <span className="block mt-1 text-sm font-semibold text-red-300 hover:text-red-500">
                        {notDefineAmount.length > 0 && (
                          <span
                            className={classNames(
                              notDefineAmount.length > 0
                                ? "cursor-pointer"
                                : "cursor-not-allowed",
                              "p-1 border rounded-md "
                            )}
                          >
                            <CsvModel
                              csvData={notDefineAmount}
                              headers={excelSMSFileHeaders}
                              fileName={`Montant non definie`}
                              text={`Voir le fichier`}
                              className={classNames(
                                notDefineAmount.length > 0
                                  ? "cursor-pointer"
                                  : "cursor-not-allowed",
                                ""
                              )}
                            />
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="relative w-full mt-1 md:mt-4">
                    {notFormatedAmount.length > 0 ? (
                      <XMarkIcon
                        className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-red-500 bg-red-500 rounded-full text-white"
                        aria-hidden="true"
                      />
                    ) : (
                      <CheckIcon
                        className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-green-500 bg-green-500 rounded-full text-white"
                        aria-hidden="true"
                      />
                    )}
                    <div className="ml-6">
                      <h4
                        className={classNames(
                          notFormatedAmount.length > 0
                            ? "text-red-500"
                            : "text-blue-500",
                          "font-bold"
                        )}
                      >
                        Montant mal formater
                      </h4>
                      <p className="max-w-screen-sm mt-2 text-sm text-gray-500">
                        Cette rubrique vérifie les utilisateurs qui ont le
                        montant mal formaté.
                      </p>
                      <span className="block mt-1 text-sm font-semibold text-red-300 hover:text-red-500">
                        {notFormatedAmount.length > 0 && (
                          <span
                            className={classNames(
                              notFormatedAmount.length > 0
                                ? "cursor-pointer"
                                : "cursor-not-allowed",
                              "p-1 border rounded-md "
                            )}
                          >
                            <CsvModel
                              csvData={notFormatedAmount}
                              headers={excelSMSFileHeaders}
                              fileName={`Montant mal formater`}
                              text={`Voir le fichier`}
                              className={classNames(
                                notFormatedAmount.length > 0
                                  ? "cursor-pointer"
                                  : "cursor-not-allowed",
                                ""
                              )}
                            />
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}

      {currentStep === 2 && (
        <div className="mt-6 space-y-8">
          <Form
            maximizeFormWidh={false}
            submitButtonLabel={`Continuer`}
            onSubmit={() => {
              setCurrentStep(currentStep + 1);
              setValidateStep([...validateStep, "2"]);
            }}
            form={{}}
          >
            <div className="flex justify-end">
              {/* <button
                type="button"
                onClick={() => {
                  setShowAddContact(true);
                }}
                className="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-red-700 transition-all duration-200 bg-white border border-red-700 rounded-md shadow-sm gap-x-2 dark:bg-slate-800 hover:border-red-900 hover:text-red-900 dark:hover:text-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                <span className="lg:hidden xl:block">Ajouter un contact</span>
              </button> */}
            </div>
            <DataTable
              title="Ajusté les données"
              // selectableRows={true}
              columns={columns}
              data={filter}
              pagination
              highlightOnHover
              subHeader
              subHeaderComponent={
                <input
                  type="text"
                  className="rounded-md"
                  placeholder="Search number here"
                  onChange={handleFilter}
                />
              }
              // contextActions={contextActions(deleteAll)}
              // selectableRowsComponentProps={selectProps}
              // onSelectedRowsChange={(e) => handleChange(e)}
            />
          </Form>
          <Modal
            isOpen={showAddContact}
            closeModal={() => setShowAddContact(false)}
            maxSize="md"
            modalTitle="Ajouter un contact"
          >
            <Form
              submitButtonLabel="Enregistrer"
              cancelButtonLabel="Retour"
              onSubmit={handleAddContact}
              onCancel={() => setShowAddContact(false)}
              form={{
                resolver: yupResolver(
                  yup.object().shape({
                    name: yup.string().optional(),
                    amount: yup.number().required("Le montant est obligatoire"),
                    number: yup
                      .string()
                      .required("Le numéro est obligatoire")
                      .matches(
                        /^\+226[0567][0123456789]{7}$/,
                        "Numéro invalide ou incomplet !"
                      ),
                  })
                ),
              }}
            >
              <FormInput
                type="text"
                name="name"
                label={"Nom"}
                placeholder="Elon Musk"
              />
              <FormInput
                required
                type="text"
                name="number"
                label={"Numéro de téléphone"}
                placeholder="+22665258555"
              />
              <FormInput
                type="number"
                name="amount"
                label="Montant"
                min={100}
                minLength={1}
                placeholder="100"
              />
            </Form>
          </Modal>
        </div>
      )}
      {currentStep === 3 && (
        <Form<{
          campaignName: string;
          message: string;
        }>
          cancelButtonLabel="Précedent"
          onCancel={() => {
            setCurrentStep(currentStep - 1);
          }}
          maximizeFormWidh={false}
          onSubmit={onSubmitPaymentFile}
          submitButtonLabel={"Lancer le paiement"}
          form={{
            resolver: yupResolver(
              yup.object().shape({
                companyName: yup
                  .string()
                  .required("Le nom de la compagnie est obligatoire")
                  .matches(
                    /^[a-zA-Z]+$/,
                    `Le nom ne doit pas contenir 
                    ni des caractères spéciaux, 
                    ni de l'espace 
                    et pas de caractères numériques. !`
                  ),
              })
            ),
          }}
        >
          <div className="py-6 bg-white dark:bg-slate-800 sm:py-6">
            <div className="flex flex-col items-center w-full px-6 lg:px-8">
              <div className="w-full mx-auto mt-2 sm:mt-5 lg:mt-6">
                <div className="flex flex-col items-center w-full max-w-sm gap-8 mx-auto md:max-w-3xl xl:max-w-sm max-xl:mx-auto">
                  <div className="w-full p-6 transition-all duration-500 border border-gray-200 rounded-3xl group hover:border-gray-400 ">
                    <div className="pb-6 leading-10 border-b border-gray-200">
                      <FormInput
                        type="text"
                        name="companyName"
                        label={"Nom de l'entreprise"}
                        placeholder="VDP"
                      />
                    </div>

                    <h2 className="pb-6 text-3xl font-bold leading-10 text-black border-b border-gray-200 font-manrope ">
                      Details du paiement
                    </h2>
                    <div className="py-6 border-b border-gray-200 data">
                      <div className="flex items-center justify-between gap-4 mb-5">
                        <p className="text-lg font-normal leading-8 text-gray-400 transition-all duration-500 group-hover:text-gray-700">
                          Total des contacts
                        </p>
                        <p className="text-lg font-medium leading-8 text-gray-900">
                          {fileData.length}
                        </p>
                      </div>
                      <div className="flex items-center justify-between gap-4 mb-5">
                        <p className="text-lg font-normal leading-8 text-gray-400 transition-all duration-500 group-hover:text-gray-700">
                          Montant total
                        </p>
                        <p className="text-lg font-medium leading-8 text-gray-600">
                          {CSVTotalAmount}
                        </p>
                      </div>
                      <div className="flex items-center justify-between gap-4 ">
                        <p className="text-lg font-normal leading-8 text-gray-400 transition-all duration-500 group-hover:text-gray-700 ">
                          Contacts
                        </p>
                        <p
                          className={classNames(
                            fileData.length > 0 &&
                              simularNumber.length <= 0 &&
                              notDefineNumber.length <= 0 &&
                              notFormatedNumber.length <= 0 &&
                              notDefineAmount.length <= 0 &&
                              notFormatedAmount.length <= 0
                              ? "text-emerald-500"
                              : "text-red-500",
                            "text-lg font-medium leading-8 "
                          )}
                        >
                          {fileData.length > 0 &&
                          simularNumber.length <= 0 &&
                          notDefineNumber.length <= 0 &&
                          notFormatedNumber.length <= 0 &&
                          notDefineAmount.length <= 0 &&
                          notFormatedAmount.length <= 0
                            ? "#VALID"
                            : "#INVALID"}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-6 total">
                      <p className="text-xl font-normal leading-8 text-black ">
                        Total
                      </p>
                      <h5 className="text-2xl font-bold leading-9 text-indigo-600 font-manrope">
                        {CSVTotalAmount}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </>
  );
};

export default AdminBulkPayment;
