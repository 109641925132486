import React, { useContext, useEffect, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { UserContext } from "../../contexts";
import Modal from "../Modal";
import { Tab } from "@headlessui/react";
import { classNames } from "../../utils";
import imageSrc from "../../sankQrCodeImg.png";
import {
  AccountInterface,
  AccountType,
  UserInterface,
  UserRole,
  accountMap,
} from "../../interfaces";
import { gasStationMembershipCollectionName } from "../../config";
import { db } from "../../firebase";

const GenerateQrcode = (props: {
  showQrcodeReadeModal: boolean;
  setShowQrcodeReadeModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser?: UserInterface;
  currentUserAccount?: AccountInterface[];
}) => {
  const {
    showQrcodeReadeModal,
    setShowQrcodeReadeModal,
    currentUserAccount,
    currentUser,
  } = props;
  const { user, userAccounts, agencyAgent } = useContext(UserContext);
  const [gasStationMembershipId, setGasStationMembershipId] = useState("");
  const imageOptions = {
    src: imageSrc,
    x: undefined,
    y: undefined,
    height: 50,
    width: 50,
    excavate: true,
  };
  const [merchandaccount, setMerchandAccount] = useState(
    (currentUserAccount ? currentUserAccount : userAccounts)?.filter(
      (account) => account.accountType === AccountType.MAIN
    )
  );

  const retriveGasStationsMembership = async () => {
    const membershipRef = db.collection(gasStationMembershipCollectionName);
    const membershipSnaphot = await membershipRef
      .where("memberId", "==", user?.id)
      .get();
    if (membershipSnaphot.docs[0].exists) {
      const membershipData = membershipSnaphot.docs[0].data();
      setGasStationMembershipId(membershipData?.gasStationId);
    }
  };
  useEffect(() => {
    user &&
      user.userRole === UserRole.GASSTATIONSTAFF &&
      retriveGasStationsMembership();
  }, []);

  const value = `${
    gasStationMembershipId
      ? gasStationMembershipId
      : (currentUser ? currentUser : agencyAgent ? agencyAgent : user)?.id
  }`;

  return (
    <Modal
      modalTitle="QrCode"
      isOpen={showQrcodeReadeModal}
      maxSize="lg"
      closeModal={() => setShowQrcodeReadeModal(false)}
    >
      <div className="w-full max-w-md px-2 py-16 sm:px-0">
        {user && user.userRole === UserRole.AGENT ? (
          <Tab.Group>
            <Tab.List className="flex p-1 space-x-1 rounded-xl bg-blue-900/20">
              <Tab
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-red-700",
                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-red-500 focus:outline-none focus:ring-2",
                    selected
                      ? "bg-white shadow"
                      : "text-red-100 hover:bg-white/[0.12] hover:text-white"
                  )
                }
              >
                Scanner le qrcode "{`${user?.agentCode}`}"
              </Tab>
            </Tab.List>
            <Tab.Panels className="mt-2">
              <Tab.Panel
                className={classNames(
                  "rounded-xl bg-white p-3 flex justify-center",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 w-full"
                )}
              >
                <QRCodeCanvas
                  className="w-full"
                  id="qrCode"
                  value={`${user?.agentCode}`}
                  size={300}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"L"}
                  includeMargin={false}
                  imageSettings={imageOptions}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        ) : (
          <Tab.Group>
            <Tab.List className="flex p-1 space-x-1 rounded-xl bg-blue-900/20">
              {((currentUser ? currentUser : user)?.userRole ===
                UserRole.MARCHAND && merchandaccount
                ? merchandaccount
                : currentUserAccount
                ? currentUserAccount
                : userAccounts
              )?.map((account, index) => (
                <Tab
                  key={index}
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-red-700",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-red-500 focus:outline-none focus:ring-2",
                      selected
                        ? "bg-white shadow"
                        : "text-red-100 hover:bg-white/[0.12] hover:text-white"
                    )
                  }
                >
                  {accountMap.get(account.accountType)}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="mt-2">
              {(currentUserAccount ? currentUserAccount : userAccounts) &&
                (currentUserAccount ? currentUserAccount : userAccounts)?.map(
                  (account, index) => (
                    <Tab.Panel
                      key={index}
                      className={classNames(
                        "rounded-xl bg-white p-3 flex justify-center",
                        "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 w-full"
                      )}
                    >
                      <QRCodeCanvas
                        className="w-full"
                        id="qrCode"
                        value={`${user?.merchantCode}`}
                        size={300}
                        level={"L"}
                        includeMargin={false}
                      />
                    </Tab.Panel>
                  )
                )}
            </Tab.Panels>
          </Tab.Group>
        )}
      </div>
    </Modal>
  );
};

export default GenerateQrcode;
