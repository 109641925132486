import { Timestamp } from "firebase-admin/firestore";
import { AccountType, UserRole, transactionMap } from "../interfaces";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function unwindObject(obj: Record<string, any>, field: string): any {
  if (Object.keys(obj).length === 0) {
    return undefined;
  }
  //The fields passed in might be "." delimmeted, so i
  //Use a loop to enter the object and get out the final needed value

  //e,g {player:{me:{she:"woman"}}}
  //To get the value woman, the field should be
  //"player.me.she"
  const fields = field.split(".");
  const field_length = fields.length;
  if (field_length <= 1) {
    return obj[field] as string;
  }

  let recuObje: any = { ...obj };
  let rolled_time = 0;

  while (recuObje && rolled_time < field_length) {
    recuObje = recuObje[fields[rolled_time]];

    rolled_time++;
  }

  /*
    to understand this while loop
    assume a length of array is called things with three elements
    things 3 (0,1,2)
    n = 0
    i) things[0]  n=1
    ii) things[1]  n=2
    ii) things[2]  n=3
    */
  return recuObje;
}

export function fromEntries<T>(entries: [keyof T, T[keyof T]][]): T {
  return entries.reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value }),
    {} as T
  );
}

export function canUseDOM() {
  return !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  );
}

/**
 * Generate a timestamp
 * @param date the date in string 2023
 * @param isFirst if the value si start time
 * @returns
 */
export const getTimestamp = (date: string, isFirst: boolean) => {
  const dateObj = new Date(date);

  dateObj.setUTCHours(isFirst ? 0 : 23);
  dateObj.setUTCMinutes(isFirst ? 0 : 59);
  dateObj.setUTCSeconds(isFirst ? 0 : 59);
  dateObj.setUTCMilliseconds(isFirst ? 0 : 999);

  // Convertir la date en timestamp
  const timestamp = Date.UTC(
    dateObj.getUTCFullYear(),
    dateObj.getUTCMonth(),
    dateObj.getUTCDate(),
    dateObj.getUTCHours(),
    dateObj.getUTCMinutes(),
    dateObj.getUTCSeconds(),
    dateObj.getUTCMilliseconds()
  );
  const formattedDateObj = new Date(timestamp);
  return formattedDateObj;
};

export const getDateTimeWithTimestamp = (date: string) => {
  const dateObj = new Date(date);

  // Convertir la date en timestamp
  const timestamp = Date.UTC(
    dateObj.getUTCFullYear(),
    dateObj.getUTCMonth(),
    dateObj.getUTCDate(),
    dateObj.getUTCHours(),
    dateObj.getUTCMinutes(),
    dateObj.getUTCSeconds(),
    dateObj.getUTCMilliseconds()
  );
  const formattedDateObj = new Date(timestamp);
  return formattedDateObj;
};

export const getTransactionLabel = (
  currentUserId: string,
  transactionType: string,
  id: string,
  accountType?: string
) => {
  switch (transactionType) {
    case "RELOAD":
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      return currentUserId === id ? "Recharger" : "Reçu";
    case "PAYMENT":
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      return currentUserId === id ? "Payement" : "Reçu";
    case "WITHDRAW":
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      return currentUserId === id ? "Reçu" : "Retrait";
    case "TRANSFER":
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      return currentUserId === id ? "Transfert" : "Reçu";
    case "BULKPAYMENT":
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      return currentUserId === id && accountType === AccountType.MAIN
        ? "Payement en masse / principal"
        : "Payement en masse / carburant";
    case "RELOADFUELACCOUNT":
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      return currentUserId === id && "Recharge carburant";
    case "BUYAIRTIME":
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      return currentUserId === id && "Recharge crédit d'appel";
    default:
      return transactionMap.get(transactionType);
  }
};

/**
 * Generate a random number
 * @param length the length of the rundom
 * @returns 12345
 */
export const generateRandomNumber = (length: number) => {
  let generatedNumber = "";
  const characters = "0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    generatedNumber += characters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }
  return generatedNumber;
};

export const parseDate = (data: any) => {
  try {
    const response = (data as Timestamp).toDate().toLocaleString();
    return response;
  } catch (error) {
    return "";
  }
};

export const formatDate = (eventDate: { seconds: number; nanoseconds: number }): string => {
  // Conversion de seconds en millisecondes
  const date = new Date(eventDate.seconds * 1000 + eventDate.nanoseconds / 1e6); 

  // Vérifiez que la date est valide
  if (isNaN(date.getTime())) {
      return 'Date invalide';
  }

  const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Africa/Ouagadougou',
  };

  // Retourne la date formatée
  return date.toLocaleDateString('fr-FR', options)
      .replace(',', '')
      .replace('à', '')
      .trim();
};

export const parseObjectDate = (date: any) => {
  const timestamp = date;
  const milliseconds =
    timestamp?.seconds * 1000 + Math.floor(timestamp?.nanoseconds / 1000000);
  const response = new Date(milliseconds);
  const jour = String(response.getDate()).padStart(2, "0");
  const mois = String(response.getMonth() + 1).padStart(2, "0"); // Notez l'ajout de 1, car les mois sont indexés de 0 à 11
  const annee = response.getFullYear();

  const dateFormat = `${jour}/${mois}/${annee}`;
  return dateFormat;
};

export const XOFCurrencyFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'XOF',
}).format;

/**
 * Amount formater
 * @param amount
 * @returns 100.000,00
 */
export const amountFormater = (amount: number) => {
  // Only 'de-DE' local give the require format
  return new Intl.NumberFormat("de-DE", {
    maximumFractionDigits: 2,
  }).format(amount);
};
/**
 * Generate a string caracters
 * @param current as a number
 * @returns SANK23000001
 */
export const createStringGenerator = (current: number) => {
  let counter = current;
  let year = new Date().getFullYear();

  if (year < new Date().getFullYear() || year > new Date().getFullYear() + 1) {
    throw new Error(
      "Invalid year. Year must be the current year or the next year."
    );
  }
  counter++;
  if (counter > 999999) {
    year++;
    counter = 1;
  }
  const formattedCounter = counter.toString().padStart(6, "0");

  return `SANK${year.toString().slice(-2)}${formattedCounter}`;
};

export function isNumeric(value: string) {
  return /^-?\d+$/.test(value);
}
export function isValidPhoneNumber(value: string) {
  return /^\+226[0567][0123456789]{7}$/.test(value);
}

export const userConnectedWithMail = [UserRole.AGENCYSTAFF, UserRole.AGENCY, UserRole.ADMIN, UserRole.GASSTATION, UserRole.GASSTATIONSTAFF, UserRole.SUPERVISOR];

export const validateAndParseCoordinate = (value: string) => {
  const parsedValue: number = parseFloat(value);
  return (value != null && value.trim() !== "" && !isNaN(parsedValue)) ? parsedValue : null;
}