import { ArrowPathIcon, EyeIcon } from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import moment from "moment";
import { useState, useCallback, useEffect, useContext } from "react";
import toast from "react-hot-toast";
import Modal from "../../components/Modal";
import Pagination from "../../components/Pagination/Pagination";
import NoContent from "../../components/TableNoContentPage/NoContent";
import { notificationsCollectionName, usersCollectionName } from "../../config";
import { functions, db } from "../../firebase";
import { getTimestamp, amountFormater, parseDate, classNames } from "../../utils";
import { UserContext } from "../../contexts";
// import { UserRole } from "../../interfaces";
// import HasRoles from "../../components/HasRoles";
import {isValidPhoneNumber} from "react-phone-number-input";

const tableHeader = [
  "N°",
  "Date de création",
  "ID",
  "Titre",
  "Message",
  "Actions",
];
// const tableHeaderForTechnicalSupport = [
//   //   "N°",
//   "Date de création",
//   "ID",
//   "Destinataire",
//   "Expéditeur",
//   "Notifications",
//   "Actions",
// ];

// export const userMap = new Map<string, string>([
//   ["DESTINATAIRE", "Destinataire"],
//   ["EXPEDITEUR", "Expéditeur"],
// ]);

const ResendNotificationsMsg = () => {
  const { user } = useContext(UserContext);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [transaction, setTransaction] = useState<any>();
  const [transDataLimitLength, setTransDataLimitLength] = useState(100);
//   const [selectedVal, setSelectedVal] = useState<string>("EXPEDITEUR");
  const [showModal, setShowModal] = useState(false);
  const [isBtnLoad, setisBtnLoad] = useState(false);
  const [userId, setUserId] = useState<string>("")
  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const ResendNotificationToUserFn =
    functions.httpsCallable("ResendNotificationToUserFn");

  const handleChangeStartDateValue = (value: any) => {
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    setSetSeachWithEndDateValue(value.target.value);
  };

  const getFilteredByDate = useCallback(async () => {
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    if (startTime > endTime) {
      return toast.error(
        "La date de fin ne doit pas etre inférieur à la date de début."
      );
    }
    const transSnap = await db
      .collection(usersCollectionName)
      .doc(userId)
      .collection(notificationsCollectionName)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .limit(transDataLimitLength)
      .get();
    if (transSnap.empty) {
      setTransactions([]);
    } else {
      const trans = transSnap.docs.map((doc) => {
        return { id: doc.id, data: doc.data() };
      });
      setTransactions(trans);
    }
    // onPageLimitChange(agensDataLimitLength, startTime, endTime);
  }, [seachWithEndDateValue, seachWithStartDateValue, transDataLimitLength, userId]);

  const filterWithPhoneNumber = useCallback(
    async(value: string) => {
        if (!isValidPhoneNumber(`${value}`, "BF")) {
            // toast.error("Le numéro de téléphone que vous avez entrer est incorrect!")    
            setPhoneNumber("")
            return;
        } else {
            if (value && `+226${value}`.length >= 12) {
                setPhoneNumber(value);
                const transSnap = await db
                .collection(usersCollectionName)
                .where("phoneNumber", "==", `+226${value}`).get()
                const id = transSnap.docs.map((el)=> el?.id);
                setUserId(id[0]);
            }
           
        }
    },
    []
  );

  const onSubmit = (notificationId: string) => {
    setisBtnLoad(true);
    const res = ResendNotificationToUserFn({
      notificationId,
      phoneNumber: `+226${phoneNumber}`,
    });
    toast.promise(
      res
        .then(() => {
          setisBtnLoad(false);
          setShowModal(false);
        })
        .catch((err) => {
          setisBtnLoad(false);
        }),
      {
        loading: "Renvoie du message en cours",
        success: "Message renvoyer avec succès.",
        error:
          "Une erreur est survenue lors du renvoie de sms. Veuillez réessayer!",
      }
    );
  };

  useEffect(() => {
    getFilteredByDate();
    // filterWithPhoneNumber();
  }, [getFilteredByDate]);

  return (
    <>
      <section className="p-3 bg-gray-50 dark:bg-gray-900 sm:p-5">
        <div className="mx-auto">
          <div className="relative overflow-hidden bg-white shadow-sm dark:bg-gray-800 sm:rounded-md">
            <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
              <div className="w-full md:w-1/2">
                <form className="flex items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    rechercher par telephone
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      onChange={(e) => filterWithPhoneNumber(e.target.value)}
                      type="text"
                      id="simple-search"
                      className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                      placeholder="Rechercher par téléphone"
                    />
                  </div>
                </form>
              </div>
              <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                <button
                  type="button"
                  className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
                  onClick={() => getFilteredByDate()}
                >
                  <ArrowPathIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1"
                    aria-hidden="true"
                  />
                  Refresh
                </button>
                <div className="flex items-center w-full space-x-3 md:w-auto">
                  <input
                    type="date"
                    id="dateDebut"
                    className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                    onChange={handleChangeStartDateValue}
                  />
                  <input
                    type="date"
                    id="dateDebut"
                    className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                    onChange={handleChangeEndDtaeValue}
                  />
                </div>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    {tableHeader.map((th, index) => (
                      <th key={index} scope="col" className="px-4 py-3">
                        {th}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {transactions.length > 0 ? (
                    transactions.map((transaction: any, transIdx: number) => (
                      <tr
                        key={transIdx}
                        className="border-b dark:border-gray-700"
                      >
                        <td className="px-4 py-3">{transIdx+1}</td>
                        <th
                          scope="row"
                          className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {(transaction.data.createdAt as Timestamp)
                            .toDate()
                            .toLocaleString()}
                        </th>
                        <td className="px-4 py-3">{transaction.id}</td>
                        <td className="px-4 py-3">
                          {transaction.data.title}
                        </td>
                        <td className={classNames(
                            transIdx !== transactions.length - 1
                            ? "border-b border-gray-200"
                            : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm relative group text-gray-500 sm:pl-6 lg:pl-8"
                        )}>
                            <span className="block truncate max-w-xs">
                                {transaction.data.message ?? "-" as string}
                            </span>
                            <span className="absolute hidden group-hover:block bg-gray-700 text-white text-sm p-2 text-wrap rounded shadow-lg max-w-2xl h-20 -top-10 left-0 z-10 whitespace-normal break-words">
                                {transaction.data.message ?? "-" as string}
                            </span>
                        </td>
                        <td className="flex items-center justify-start px-4 py-3">
                          <button
                            type="button"
                            className="inline-flex justify-center w-full px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700 lg:w-auto"
                            onClick={() => {
                              setTransaction(transaction);
                              setShowModal(true);
                            }}
                          >
                            <EyeIcon
                              className="w-5 h-5 mr-3"
                              aria-hidden="true"
                            />
                            details
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoContent />
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              skip={transactions.length}
              take={transactions.length}
              total={transactions.length}
              collectionName={""}
              onPageChange={(pageIndex: number) => pageIndex}
              onPageLimitChange={(pageLimit: number) => {
                setTransDataLimitLength(pageLimit);
              }}
              isChangedPage={false}
            />
          </div>
        </div>
      </section>
      <Modal
        isOpen={showModal}
        maxSize="md"
        closeModal={() => {
          setTransaction(undefined);
          setShowModal(false);
        }}
      >
        <section className="py-4 antialiased bg-white dark:bg-gray-900 md:py-8">
          <div className="max-w-2xl px-4 mx-auto 2xl:px-0">
            <h2 className="mb-2 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              #{transaction?.id}
            </h2>
            <div className="p-6 mb-6 space-y-4 border border-gray-100 rounded-lg sm:space-y-2 bg-gray-50 dark:border-gray-700 dark:bg-gray-800 md:mb-8">
              <dl className="items-center justify-between gap-4 sm:flex">
                <dt className="mb-1 font-normal text-gray-500 sm:mb-0 dark:text-gray-400">
                  Date de création
                </dt>
                <dd className="font-medium text-gray-900 dark:text-white sm:text-end">
                  {parseDate(transaction?.data.createdAt as Timestamp)}
                </dd>
              </dl>
              <dl className="items-center justify-between gap-4 sm:flex">
                <dt className="mb-1 mt-6 font-normal text-gray-500 sm:mb-0 dark:text-gray-400">
                  Message
                </dt>
                
              </dl>
              <dl>
              <dd className="w-full mt-2 font-medium text-gray-900 dark:text-white sm:text-start">
                  {transaction?.data.message}s
                </dd>
              </dl>
            </div>
            <div className="flex items-center space-x-4">
              <button
                type="button"
                className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
                onClick={() => onSubmit(transaction?.id)}
                disabled={isBtnLoad}
              >
                {isBtnLoad ? (
                  <svg
                    className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Renvoyer le message"
                )}
              </button>
              <button
                type="button"
                className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onClick={() => {
                  setTransaction(undefined);
                  setShowModal(false);
                }}
                disabled={isBtnLoad}
              >
                Annuler
              </button>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
};

export default ResendNotificationsMsg;
