import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MapPinIcon, AdjustmentsHorizontalIcon, UserGroupIcon, CalendarIcon, EnvelopeIcon, PhoneIcon, UserIcon, TicketIcon, BanknotesIcon, PhotoIcon } from "@heroicons/react/24/outline";
import { eventsCollections, ticketsTypesCollections } from "../../config";
import { db } from "../../firebase";
import { Timestamp } from "firebase-admin/firestore";
import { classNames, formatDate, parseDate } from "../../utils";
import { UserContext } from "../../contexts";
import { UserRole } from "../../interfaces";
import { EventsStatus } from "../../interfaces/ticket.interface";



const EventDetailsPage = () => {
  const location = useLocation();
  const eventId = location.state?.eventId;
  const { user } = useContext(UserContext)
  const [event, setevent] = useState<any>()
  const [organizer, setorganizer] = useState<any>()
  const [ticketTypes, setTicketTypes] = useState<any[]>([])
  const navigate = useNavigate();
  const [userId, setUserId] = useState("")
  const [currentDay, setcurrentDay] = useState<Date>(new Date());

  const getEventWithId = useCallback(async () => {
    const getEventRef = db
      .collection(eventsCollections)
      .doc(eventId)

    await getEventRef.get().then((event) => {
      setevent(event.data());
      organiserRef(event.data()?.organizatorRef);
    })
  }, [eventId])

  const organiserRef = useCallback(async (organizatorRef: any) => {
    try {
      const organizatorDoc = await organizatorRef.get();
      if (organizatorDoc.exists) {
        setUserId(organizatorDoc.id)
        setorganizer({ ...organizatorDoc.data(), id: organizatorDoc.id });
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du document:", error);
    }
  }, [])

  const ticketsTypes = useCallback(async () => {
    const getEventRef = db
      .collection(eventsCollections)
      .doc(eventId)
      .collection(ticketsTypesCollections)
    await getEventRef.get().then((event) => {
      setTicketTypes(
        event.docs.map((element) => {
          return { id: element?.id, data: element?.data() }
        })
      )
    })
  }, [eventId])

  useEffect(() => {
    getEventWithId()
    ticketsTypes();
  }, [])

  return (
    <>
      {(user?.userRole === UserRole.MARCHAND && (event?.eventImageUrls && event?.eventImageUrls[0] === "")) && (
        <div className="w-full h-24 bg-red-500 flex justify-center p-3 rounded-md">
          <p className="text-white text-lg m-auto uppercase">vous devez rajouter des images pour compléter la création de l'evenement</p>
        </div>
      )}
      <div className="grid grid-cols-1 grid-rows-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        <div
          className="me-4 block rounded-lg bg-slate-200 shadow-secondary-1 dark:bg-surface-dark dark:text-white text-surface">
          <div className="p-6">
            <div className="flex justify-between pb-3 mb-4 border-b border-gray-500">
              <h5
                className="mb-2 text-xl font-medium leading-tight">
                Informations de l' événement
              </h5>
              {
                (user?.userRole === UserRole.MARCHAND || user?.userRole === UserRole.ADMIN) && (
                  <button
                    type="button"
                    className={classNames(
                      (new Date(event?.eventEndDate.toDate()) < new Date()) ? "bg-gray-300 px-4 py-2 rounded-md cursor-not-allowed opacity-50"
                        : "",
                      "inline-block rounded bg-blue-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                    )}
                    disabled={new Date(event?.eventEndDate.toDate()) < new Date()}
                    onClick={() => {
                      const startTime = (event?.eventStartDate as Timestamp).toDate();
                      const endTime = (event?.eventEndDate as Timestamp).toDate();
                      navigate(`/billetterie/event/${eventId}/edit`, {
                        state: {
                          entity: {
                            id: eventId,
                            title: event ? event?.title : "",
                            eventStartTime: startTime,
                            eventEndTime: endTime,
                            locationName: event ? event?.locationName : "",
                            city: event ? event?.city : "",
                            maxCapacity: event ? event?.maxCapacity : "",
                            long: event ? event?.long : "",
                            lat: event ? event?.lat : "",
                            description: event ? event?.description : "",
                            eventImageUrls: event ? event?.eventImageUrls : "",
                          }
                        }
                      });
                    }}
                  >
                    Edit
                  </button>
                )
              }

            </div>
            <div>
              <div className="py-2 space-y-3 " tabIndex={0}>
                <div className="relative flex items-center">
                  <div className="object-cover w-[57px] h-[57px] flex justify-center rounded-xl bg-gray-100">
                    <AdjustmentsHorizontalIcon
                      className="self-center flex-shrink-0 w-7 h-7 mr-1 text-gray-800"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="flex flex-col ml-4 sm:w-96">
                    <div className="text-md text-gray-400">
                      <span className="mr-1 font-medium text-gray-900">
                        {event?.title ?? ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-2 space-y-3 " tabIndex={0}>
                <div className="relative flex items-center">
                  <div className="object-cover w-[57px] h-[57px] flex justify-center rounded-xl bg-gray-100">
                    <CalendarIcon
                      className="self-center flex-shrink-0 w-7 h-7 mr-1 text-gray-800"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="flex flex-col ml-4 sm:w-96">
                    {
                      new Date(event?.eventEndDate.toDate()) < new Date() ? (
                        <p className="mb-1 font-medium text-red-700">
                          L'evenement est terminé
                        </p>
                      ) : (
                        <>
                          <p className="mb-1 font-medium text-gray-700">
                            Du {parseDate(event?.eventStartDate as Timestamp)}<br /> au <br />
                            {parseDate(event?.eventEndDate as Timestamp)}
                          </p>
                        </>
                      )
                    }

                  </div>
                </div>
              </div>
              <div className="py-2 space-y-3 " tabIndex={0}>
                <div className="relative flex items-center">
                  <div className="object-cover w-[57px] h-[57px] flex justify-center rounded-xl bg-gray-100">
                    <MapPinIcon
                      className="self-center flex-shrink-0 w-7 h-7 mr-1 text-gray-800"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="flex flex-col ml-4 sm:w-96">
                    <p className="mb-1 font-medium text-gray-900">
                      {event?.locationName ?? ""}<br />
                      {event?.city ?? ""}
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative flex items-center">
                <div className="object-cover w-[57px] h-[57px] flex justify-center rounded-xl bg-gray-100">
                  <UserGroupIcon
                    className="self-center flex-shrink-0 w-7 h-7 mr-1 text-gray-800"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex flex-col ml-4 sm:w-96">
                  <div className="text-sm text-gray-400">
                    <span className="text-gray-900 font-bold">
                      Nombre de place disponible
                    </span>
                    <br />
                    <span className="mr-1 font-medium text-gray-500">
                      {parseInt(event?.maxCapacity)} places
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center p-4">
            <div className="mx-auto w-full max-w-[550px] bg-slate-200">
              <div className="pt-2">
                {(event?.eventImageUrls && event?.eventImageUrls[0] !== "") ? (
                  <img
                    className="object-cover object-center mx-auto"
                    src={event?.eventImageUrls[0]}
                    alt={event?.eventImageUrls[0]}
                  />
                ) : (
                  <span className="inline-flex rounded border border-[#e0e0e0] cursor-pointer py-2 px-7 text-base font-medium text-[#07074D]">
                    <PhotoIcon
                      className="self-center flex-shrink-0 w-10 h-10 mr-1 text-gray-800"
                      aria-hidden="true"
                    />
                    Aucune image
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="block rounded-lg bg-slate-200 shadow-secondary-1 dark:bg-surface-dark dark:text-white text-surface">
          <div className="p-6">
            <div className="flex justify-between pb-3 mb-4 border-b border-gray-800">
              <h5
                className="mb-2 text-xl font-medium leading-tight">
                Informations de l'organiseur
              </h5>
              {
                (user?.userRole === UserRole.MARCHAND || user?.userRole === UserRole.ADMIN) && (
                  <button
                    type="button"
                    onClick={() => {
                      navigate(`/billetterie/organizer/${userId}/edit`, {
                        state: { entity: organizer, eventId: eventId }
                      });
                    }}
                    className={classNames(
                      (new Date(event?.eventEndDate.toDate()) < new Date()) ? "bg-gray-300 px-4 py-2 rounded-md cursor-not-allowed opacity-50"
                        : "",
                      "inline-block rounded bg-blue-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                    )}
                    disabled={new Date(event?.eventEndDate.toDate()) < new Date()}
                  >
                    Edit
                  </button>
                )
              }
            </div>
            <div>
              <div className="py-2 space-y-3 " tabIndex={0}>
                <div className="relative flex items-center">
                  <div className="object-cover w-[57px] h-[57px] flex justify-center rounded-lg bg-gray-100">
                    <UserIcon
                      className="self-center flex-shrink-0 w-7 h-7 mr-1 text-gray-800"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="flex flex-col ml-4 sm:w-96">
                    <p className="mb-1 font-medium text-gray-700">
                      {organizer?.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="py-2 space-y-3 " tabIndex={0}>
                <div className="relative flex items-center">
                  <div className="object-cover w-[57px] h-[57px] flex justify-center rounded-lg bg-gray-100">
                    <EnvelopeIcon
                      className="self-center flex-shrink-0 w-7 h-7 mr-1 text-gray-800"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="flex flex-col ml-4 sm:w-96">
                    <p className="mb-1 font-medium text-gray-700">
                      {organizer?.email}
                    </p>
                  </div>
                </div>
              </div>
              <div className="py-2 space-y-3 " tabIndex={0}>
                <div className="relative flex items-center">
                  <div className="object-cover w-[57px] h-[57px] flex justify-center rounded-lg bg-gray-100">
                    <PhoneIcon
                      className="self-center flex-shrink-0 w-7 h-7 mr-1 text-gray-800"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="flex flex-col ml-4 sm:w-96">
                    <p className="mb-1 font-medium text-gray-700">
                      {organizer?.phoneNumber}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          ticketTypes.length > 0 && ticketTypes.map((type: any, typeIndex: number) => (
            <div className="block rounded-lg bg-slate-200 shadow-secondary-1 dark:bg-surface-dark dark:text-white text-surface" key={typeIndex}>
              <div className="p-6">
                <div className="flex justify-between pb-3 mb-4 border-b border-gray-800">
                  <h5
                    className="text-xl font-medium leading-tight">
                    Le type de ticket : <span className="text-red-500"> {type.data?.standard} </span>
                  </h5>
                  {
                    (user?.userRole === UserRole.MARCHAND || user?.userRole === UserRole.ADMIN) && (
                      <button type="button"
                        className={classNames(
                          (new Date(type.data?.saleEndDate.toDate()) < currentDay) ? "bg-gray-300 px-4 py-2 rounded-md cursor-not-allowed opacity-50"
                            : "",
                          "inline-block rounded bg-blue-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                        )}
                        disabled={new Date(type.data?.saleEndDate.toDate()) < currentDay}
                        onClick={() => {
                          navigate(`/billetterie/events/${userId}/detail_ticketType`, {
                            state: {
                              entity: {
                                id: type.data?.id,
                                availableQuantity: type.data?.availableQuantity,
                                numberOfPlace: type.data?.numberOfPlace,
                                saleEndDate: (type.data?.saleStartDate as Timestamp).toDate(),
                                saleStartDate: (type.data?.saleEndDate as Timestamp).toDate(),
                                standard: type.data?.standard,
                                ticketCoverUrl: type.data?.ticketCoverUrl ?? "",
                                ticketExpiringDate: (type.data?.ticketExpiringDate as Timestamp).toDate(),
                                ticketPrice: type.data?.ticketPrice,
                                eventId: eventId,
                              }
                            }
                          });
                        }}
                      >
                        Edit
                      </button>
                    )
                  }

                </div>
                <div className="">
                  <div className="relative flex items-center mb-2">
                    <div className="object-cover w-[57px] h-[57px] flex justify-center rounded-lg bg-gray-100">
                      <UserGroupIcon
                        className="self-center flex-shrink-0 w-7 h-7 mr-1 text-gray-800"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="flex flex-col ml-4 sm:w-96">
                      <p className="font-medium text-gray-700">
                        {type.data?.initialQuantity} <span className="text-sky-700">Tickets disponible</span>
                      </p>
                    </div>
                  </div>
                  <div className="relative flex items-center">
                    <div className="object-cover w-[57px] h-[57px] flex justify-center rounded-lg bg-gray-100">
                      <UserGroupIcon
                        className="self-center flex-shrink-0 w-7 h-7 mr-1 text-gray-800"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="flex flex-col ml-4 sm:w-96">
                      <p className="font-medium text-gray-700">
                        {type.data?.availableQuantity} <span className="text-sky-700">Tickets restants</span>
                      </p>
                    </div>
                  </div>
                  <div className="relative flex items-center">
                    <div className="object-cover w-[57px] h-[57px] flex justify-center rounded-lg bg-gray-100">
                    <TicketIcon
                        className="self-center flex-shrink-0 w-7 h-7 mr-1 text-gray-800"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="flex flex-col ml-4 sm:w-96">
                      <p className="font-medium text-gray-700">
                        {type.data?.initialQuantity - type.data?.availableQuantity} <span className="text-sky-700">Tickets vendus</span>
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <div className="relative flex items-center">
                      <div className="object-cover w-[57px] h-[57px] flex justify-center rounded-lg bg-gray-100">
                        <CalendarIcon
                          className="self-center flex-shrink-0 w-7 h-7 mr-1 text-gray-800"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex flex-col ml-4 sm:w-96">
                        {
                          new Date(type.data?.saleEndDate.toDate()) < currentDay ? (
                            <p className="mb-1 font-medium text-red-700">
                              La vente est terminé
                            </p>
                          ) : (
                            <>
                              <p className="mb-1 font-medium text-gray-700">
                                Disponibilité des tickets<br />
                                Du <span className="text-sky-700"> {formatDate(type.data?.saleStartDate as Timestamp)} </span> <br />
                                au <span className="text-sky-700"> {formatDate(type.data?.saleEndDate as Timestamp)} </span>
                              </p>
                            </>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className="relative flex items-center mb-1">
                    <div className="object-cover w-[57px] h-[57px] flex justify-center rounded-lg bg-gray-100">
                      <TicketIcon
                        className="self-center flex-shrink-0 w-7 h-7 mr-1 text-gray-800"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="flex flex-col ml-4 sm:w-96">
                      <p className=" font-medium text-gray-700">
                        {type.data?.standard}
                      </p>
                    </div>
                  </div>
                  <div className="relative flex items-center mb-2">
                    <div className="object-cover w-[57px] h-[57px] flex justify-center rounded-lg bg-gray-100">
                      <BanknotesIcon
                        className="self-center flex-shrink-0 w-7 h-7 mr-1 text-gray-800"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="flex flex-col ml-4 sm:w-96">
                      <p className="font-medium text-gray-700">
                        Le ticket expire le {" "} <br />
                        <span className="text-red-600"> {formatDate(type.data?.ticketExpiringDate as Timestamp)} </span>
                      </p>
                    </div>
                  </div>
                  <div className="relative flex items-center">
                    <div className="object-cover w-[57px] h-[57px] flex justify-center rounded-lg bg-gray-100">
                      <CalendarIcon
                        className="self-center flex-shrink-0 w-7 h-7 mr-1 text-gray-800"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="flex flex-col ml-4 sm:w-96">
                      <p className="font-medium text-gray-700">
                        {type.data?.ticketPrice} FCFA
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center p-4">
                  <div className="mx-auto w-full max-w-[550px] bg-slate-200">
                    <div className="pt-2">
                      {(type.data?.ticketCoverUrl && type.data?.ticketCoverUrl.length > 0) ? (
                        <img
                          className="object-cover object-center mx-auto"
                          src={type.data?.ticketCoverUrl}
                          alt={type.data?.ticketCoverUrl}
                        />
                      ) : (
                        <span className="inline-flex rounded border border-[#e0e0e0] cursor-pointer py-2 px-7 text-base font-medium text-[#07074D]">
                          <PhotoIcon
                            className="self-center flex-shrink-0 w-10 h-10 mr-1 text-gray-800"
                            aria-hidden="true"
                          />
                          Aucune image
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </>
  );
};

export default EventDetailsPage;
