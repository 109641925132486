import { useCallback, useContext, useEffect, useState } from "react";
// import FolderComponent from "../../../components/FolderComponent";
import { db } from "../../../firebase";
import { UserContext } from "../../../contexts";
import {
  companyCollectionName,
  companyReportCollectionsName,
} from "../../../config";
// import { useNavigate } from "react-router-dom";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import moment from "moment";
import { amountFormater, classNames, getTimestamp, parseDate } from "../../../utils";
import { Timestamp } from "firebase-admin/firestore";
// import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";

const tableHeader = [
  "Date de Paiement",
  "Nom de la compagnie",
  "Description",
  "Montant total payé",
  "Nombre total de transactions payées",
  "Nombre total de transactions non payées",
  "Fichier de rapport des transactions",
  // "Actions"
];

const ReportCompanyTransactions = () => {
  const { company } = useContext(UserContext);
  const [reports, setReports] = useState<any>([]);
  // const navigate = useNavigate();

  const [startTime, setStartTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [endTime, setEndTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );

  const handleChangeStartDateValue = (value: any) => {
    const startTime = getTimestamp(value.target.value, true);
    setStartTime(startTime);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    const endTime = getTimestamp(value.target.value, false);
    setEndTime(endTime);
  };

  const retirveReports = useCallback(async () => {
    const reportsSnap = await db
      .collection(companyCollectionName)
      .doc(company?.id)
      .collection(companyReportCollectionsName)
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .orderBy("createdAt", "desc")
      .get();
    if (reportsSnap.empty) {
      setReports([]);
    } else {
      const data = reportsSnap.docs.map((doc) => {
        return { data: doc.data() };
      });
      setReports(data);
    }
  }, [company?.id, endTime, startTime]);

  useEffect(() => {
    company && retirveReports();
  }, [company, retirveReports]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <div className="ml-2 sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les rapports de paiement en masse
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ici l'ensemble des rapports de paiement en masse.
          </p>
        </div>
        <div className="h-6" />
        <div
          className="flex flex-col items-end justify-end p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4"
        >
          <div className="px-1 bg-white border border-gray-200 rounded-lg sm:flex-wrap md:flex-wrap lg:flex dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
            <div className="mr-1">
              <label
                htmlFor="dateDebut"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Date début
              </label>
              <input
                type="date"
                id="dateDebut"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeStartDateValue}
              />
            </div>
            <div className="mr-1">
              <label
                htmlFor="dateFin"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Date fin
              </label>
              <input
                type="date"
                id="dateFin"
                className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeEndDtaeValue}
              />
            </div>
          </div>
        </div>
        <div className="mt-8 overflow-x-auto">

          <table
            className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
            style={{ borderSpacing: 0 }}
          >
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                {tableHeader.map((th, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    {th}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white">
              {reports && reports.length > 0 ? (
                reports.map((data: any, referralIdx: number) => (
                  <tr key={referralIdx}>
                    <td
                      className={classNames(
                        referralIdx !== reports.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {parseDate(data.data?.createdAt as Timestamp)}
                    </td>
                    <td
                      className={classNames(
                        referralIdx !== reports.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {data.data?.companyName ?? "-"}
                    </td>
                    <td
                      className={classNames(
                        referralIdx !== reports.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {data.data?.campaignDescription ?? "-"}
                    </td>
                    <td
                      className={classNames(
                        referralIdx !== reports.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {data.data?.totalPaidTransactionsAmount ? amountFormater(data.data?.totalPaidTransactionsAmount) : 0}{" "}
                      fcfa
                    </td>
                    <td
                      className={classNames(
                        referralIdx !== reports.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {data.data?.totalPaidTransactions}
                    </td>
                    <td
                      className={classNames(
                        referralIdx !== reports.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {data.data?.totalUnPaidTransactions ? amountFormater(
                        data.data?.totalUnPaidTransactions
                      ) : 0}{" "}
                    </td>
                    <td
                      className={classNames(
                        referralIdx !== reports.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      <a
                        type="button"
                        href={data.data?.reportUrl}
                        download={`Rapport_du_fichier_Des_Transactions_Non_Paye_${new Date().toDateString()}`}
                        className="inline-flex items-center gap-1.5 rounded-lg border border-primary-500 bg-primary-500 px-5 py-2.5 text-center text-sm font-medium shadow-sm transition-all hover:border-primary-700 hover:bg-primary-700 focus:ring focus:ring-primary-200 disabled:cursor-not-allowed disabled:border-primary-300 disabled:bg-primary-300"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="w-4 h-4 text-green-500 "
                        >
                          <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
                          <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                        </svg>
                        Télécharger
                      </a>
                    </td>
                    {/* <td
                            className={classNames(
                              referralIdx !== batchData.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            <TableRowEditMenu
                              rowRefreshDetailsHandler={() => reloadFilesUrls(data.data, data.id)}
                            />
                          </td> */}
                  </tr>
                ))
              ) : (
                <NoContent />
              )}
            </tbody>
          </table>
          {/* {batchData && batchData.length > 0 && (
                  <Pagination
                    skip={batchData.length}
                    take={batchData.length}
                    total={batchData.length}
                    collectionName={companyCollectionName}
                    onPageChange={(pageIndex: number) => pageIndex * page}
                    onPageLimitChange={(pageLimit: number) =>
                      getFilteredByDate(pageLimit)
                    }
                    isChangedPage={false}
                  />
                )} */}
        </div>
      </div>
    </div>
    // <div className="flex flex-col max-w-3xl mx-4 ">
    //   <p className="pb-4 my-4 text-3xl font-bold leading-none tracking-tight text-gray-900 border-b border-gray-200 sm:text-4xl md:text-5xl lg:text-2xl dark:text-white">
    //     Vos rapports
    //   </p>
    //   <div className="grid grid-cols-4 my-3 lg:grid-cols-8 gap-x-6">
    //     {reports ? (
    //       Object.keys(reports).map((val, index) => (
    //         <FolderComponent
    //           key={index}
    //           title={val}
    //           onclick={() =>
    //             navigate(`/companies/transactions-report/items/${val}`, {
    //               state: {
    //                 reportIems: reports[val],
    //               },
    //             })
    //           }
    //         />
    //       ))
    //     ) : (
    //       <NoContent />
    //     )}
    //   </div>
    // </div>
  );
};

export default ReportCompanyTransactions;
