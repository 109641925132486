/* eslint-disable jsx-a11y/no-redundant-roles */
import {
  CheckIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useContext, useEffect, useState } from "react";
import { db, functions } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts";
import {
  categorieCollections
} from "../../config";
import toast from "react-hot-toast";
import { TicketInterface } from "../../interfaces/ticket.interface"
import {
  Form,
  FormInput,
} from "../../components/FormComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { classNames } from "../../utils";


type TicketAttendantInfos = Pick<
  TicketInterface,
  | "title"
  | "eventStartTime"
  | "eventEndTime"
  | "adresse"
  | "city"
  | "numberOfTicket"
  | "lat"
  | "long"
  | "saleStartDate"
  | "saleEndDate"
  | "availableQuantity"
  | "ticketExpiringDate"
  | "price"
  | "standard"
>;

const steps = [
  {
    step: 1,
    title: "Renseigner les informations pour creer un evenement",
  },
  // {
  //   step: 2,
  //   title: "Choix de categorie",
  // },
  {
    step: 2,
    title: "Création des differents types de tickets",
  },
]

const EventPage = () => {
  const navigate = useNavigate();
  const [currentFormStep, setCurrentFormStep] = useState(1);
  const [validateStep, setValidateStep] = useState<string[]>([""]);
  const { user } = useContext(UserContext);
  const [categories, setCategories] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [description, setDescription] = useState("");
  const [formField, setFormField] = useState<TicketInterface>()
  const [disabled, setDisabled] = useState<boolean>(false)
  const [inputFields, setInputFields] = useState<any[]>([
    { saleStartDate: null, saleEndDate: null, availableQuantity: 0, price: 0, standard: '', ticketExpiringDate: null, ticketCoverUrl: '', numberOfPlace: 0 }
  ])
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const createdEventsFn = functions.httpsCallable(
    "createdEventsFn"
  );

  const handleFormChange = (index: number, event: any) => {

    const { name, value } = event.target;
  const updatedInputs = [...inputFields];
  
  // Convertir les valeurs datetime-local en format ISO pour manipulation facile
  updatedInputs[index][name] = name.includes('Date') ? (value ? new Date(value).toISOString() : null) : value;

    // let data = [...inputFields];
    // data[index][event.target.name] = event.target.value;
    setInputFields(updatedInputs);

    if (event.target.value) {
      setErrors(prevErrors => ({ ...prevErrors, [event.target.name]: '' }));
    }
  }

  const addFields = () => {
    let newFormField = { saleStartDate: null, saleEndDate: null, availableQuantity: 0, price: 0, standard: '', ticketExpiringDate: null, ticketCoverUrl: '', numberOfPlace: 0 }
    setInputFields([...inputFields, newFormField])
  }

  const removeFields = (index: number) => {
    let data = [...inputFields];
    data.splice(index, 1)
    setInputFields(data)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategory(event.target.value);
  };

  const handleDescriptionChange = (e: any) => {
    const value = e.target.value;
    setDescription(value);
  };

  const onSubmitDefaultInfos = async () => {
    if (user) {
      const newErrors: { [key: string]: string } = {};
      inputFields.forEach((input, index) => {
        if (!input.saleStartDate) {
          newErrors[`saleStartDate${index}`] = "La date de début de vente est obligatoire";
        }
        if (!input.saleEndDate) {
          newErrors[`saleEndDate${index}`] = "La date de fin de vente est obligatoire";
        }
        if (!input.availableQuantity || isNaN(input.availableQuantity)) {
          newErrors[`availableQuantity${index}`] = "Le nombre de ticket obligatoire ou mal défini ";
        }
        if (!input.price || isNaN(input.price)) {
          newErrors[`price${index}`] = "Le prix du ticket est obligatoire ou mal défini";
        }
        if (!input.standard) {
          newErrors[`standard${index}`] = "La date de fin de vente est obligatoire";
        }
        if (!input.ticketExpiringDate) {
          newErrors[`ticketExpiringDate${index}`] = "La date de fin de vente est obligatoire";
        }
        if (!input.numberOfPlace || isNaN(input.numberOfPlace)) {
          newErrors[`numberOfPlace${index}`] = "Le nombre de place par ticket est obligatoire ou mal défini";
        }
      });
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      }
      else {
        const toastId = toast.loading("Création d'evenement...");
        setDisabled(true)
        const data = {
          title: formField?.title,
          description: description,
          maxCapacity: formField?.numberOfTicket,
          eventStartDate: formField?.eventStartTime,
          eventEndDate: formField?.eventEndTime,
          locationName: formField?.adresse,
          merchantId: user?.id,
          eventImageUrls: "",
          category: selectedCategory,
          long: formField?.long,
          lat: formField?.lat,
          city: formField?.city,
          types: inputFields,
        }
        createdEventsFn(data)
          .then(() => {
            navigate("/billetterie");
            toast.success("L' évenement a été crée avec succès.", {
              id: toastId,
            });
          }
        ).catch((e) => {
            toast.error(`Erreur lors de la creation : ${e}`, {
              id: toastId,
            });
            setDisabled(false);
          })
      }
    }
  };

  const getCategories = async () => {
    const getCategorieRef = db
      .collection(categorieCollections)

    await getCategorieRef.get()
      .then((categorie) => {
        setCategories(
          categorie.docs.map((element) => {
            return { id: element.id, data: element.data() }
          })
        )
      })
  }

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <div className="py-2 border-b border-gray-200 dark:border-slate-700">
        <h2 className="text-lg font-semibold leading-6 text-gray-900 dark:text-gray-300">
          Evenements
        </h2>
        <p className="mt-1 text-base text-gray-500">
          Création d' evenement
        </p>
      </div>
      <nav aria-label="Progress">
        <ol className="my-4 border border-gray-300 divide-y divide-gray-300 rounded-md dark:border-slate-700 dark:divide-slate-700 md:flex md:divide-y-0">
          {steps.map((element) => {
            return (
              <li key={element.step} className="relative md:flex md:flex-1">
                {element.step === currentFormStep ? (
                  <div className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-red-600 rounded-full">
                      <span className="text-red-600">{element.step}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-red-600">
                      {element.title}
                    </span>
                  </div>
                ) : validateStep.includes(`${element.step}`) ? (
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-red-600 rounded-full group-hover:bg-red-800">
                      <CheckIcon
                        className="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900 dark:text-gray-400">
                      {element.title}
                    </span>
                  </span>
                ) : (
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-gray-300 rounded-full dark:border-slate-700 group-hover:border-gray-400">
                      <span className="text-gray-500 group-hover:text-gray-900">
                        {element.step}
                      </span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {element.title}
                    </span>
                  </span>
                )}
                {element.step !== steps.length && (
                  <div
                    className="absolute top-0 right-0 hidden w-5 h-full md:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="w-full h-full text-gray-300 dark:text-slate-700"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
      {
        currentFormStep === 1 && (
          <div className="flex mx-5 mt-4 max-md:block max-md:space-x-0 max-md:space-y-10 max-md:mx-5">
            <div className="w-full">
              <div className="flex flex-col w-full">
                <div className="flex justify-center w-full">
                  <div className="w-full max-w-4xl px-2 py-8 sm:px-0">
                    <div className="flex items-center justify-center w-full font-manrope">
                      <div className="box-border w-full p-4 mx-auto bg-white border">
                        <Form<TicketAttendantInfos>
                          maximizeFormWidh={false}
                          formReturnAware={() => setFormField}
                          onSubmit={(data: any) => {
                            setFormField(data);
                            setCurrentFormStep(currentFormStep + 1);
                            setValidateStep([...validateStep, "1"]);
                          }}
                          submitButtonLabel={"Continuer"}
                          form={{
                            resolver: yupResolver(
                              yup.object().shape({
                                title: yup
                                  .string()
                                  .required("Le champ est obligatoire!"),
                                eventStartTime: yup.string().required("Le champ est obligatoire!"),
                                eventEndTime: yup.string().required("Le champ est obligatoire!"),
                                adresse: yup.string().required("Le champ est obligatoire!"),
                                city: yup.string().required("Le champ est obligatoire"),
                                numberOfTicket: yup.number().required("Le champ est obligatoire"),
                                long: yup.string().required("Le champ est obligatoire"),
                                lat: yup.string().required("Le champ est obligatoire"),
                              })
                            ),
                            defaultValues: {
                              title: formField?.title ?? "",
                              eventStartTime: formField?.eventStartTime ?? "",
                              eventEndTime: formField?.eventEndTime ?? "",
                              adresse: formField?.adresse ?? "",
                              city: formField?.city ?? "",
                              numberOfTicket: formField?.numberOfTicket ?? 0 ,
                              long: formField?.long ?? "",
                              lat: formField?.lat ?? ""
                            },
                          }}
                        >
                          <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
                            <div className="w-full mx-auto md:w-1/2" >
                              <FormInput
                                required
                                type="text"
                                name="title"
                                label={`Titre`}
                                placeholder="Sank festival"
                              />
                              <FormInput
                                type="datetime-local"
                                label="Date de debut"
                                name="eventStartTime"
                              />
                              <FormInput
                                type="datetime-local"
                                label="Date de fin"
                                name="eventEndTime"
                              />
                              <FormInput
                                required
                                type="text"
                                name="adresse"
                                label={`Lieu`}
                                placeholder="secteur 30, Ouaga 2000"
                              />
                              <FormInput
                                required
                                type="text"
                                name="long"
                                label={`Longitude`}
                                placeholder="-1.002546"
                              />
                            </div>
                            <div className="w-10 h-5"></div>
                            <div className="w-full mx-auto md:w-1/2" >
                              <FormInput
                                required
                                type="text"
                                name="lat"
                                label={`Latitude`}
                                placeholder="12.00003"
                              />
                              <FormInput
                                required
                                type="text"
                                name="city"
                                label={`Ville`}
                                placeholder="Ouagadougou"
                              />
                              <FormInput
                                required
                                type="number"
                                name="numberOfTicket"
                                label={`Nombre de place`}
                                placeholder="1000"
                              />
                              <div className="font-semibold">
                                Entrez une descriptions de l'évènement
                              </div>
                              <div>
                                <textarea
                                  id="message"
                                  rows={4}
                                  onChange={handleDescriptionChange}
                                  maxLength={200}
                                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                                  placeholder="Écrivez votre description ici..."
                                ></textarea>
                              </div>
                            </div>

                          </div>
                          <div className="box-border w-full p-2 mx-auto bg-white border">
                            Les categories
                            <div className=" mb-2 flex justify-start flex-wrap ">
                              {
                                categories.map((el: any, elIndex: number) => (
                                  <div className="ml-4" key={elIndex}>
                                    <input
                                      className="relative float-left -ms-[1.5rem] me-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-secondary-500 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-checkbox before:shadow-transparent before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-black/60 focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-checkbox checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] rtl:float-right dark:border-neutral-400 dark:checked:border-primary"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id={`radioDefault-${elIndex}`}
                                      value={el.data?.categoryType}
                                      checked={selectedCategory === el.data?.categoryType}
                                      onChange={handleChange}
                                    />
                                    <label
                                      className="mt-px inline-block ml-2 ps-[0.15rem] hover:cursor-pointer"
                                      htmlFor="radioDefault01">
                                      {
                                        el.data?.categoryType
                                      }
                                    </label>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        currentFormStep === 2 && (
          <div className="flex mx-5 mt-4 max-md:block max-md:space-x-0 max-md:space-y-10 max-md:mx-5">
            <div className="w-full">
              <div className="flex flex-col w-full">
                <div className="flex justify-center w-full">
                  <div className="w-full max-w-4xl px-2 py-8 sm:px-0">
                    <div className="flex items-center justify-center w-full font-manrope">
                      <div className="box-border w-full p-4 mx-auto bg-white border">

                        <form action="" className="max-w-2xl mx-auto mt-4">
                          {
                            inputFields.map((input, index: number) => {
                              return (
                                <div key={index} className="box-border w-full p-4 mx-auto border mb-3">
                                  <div className="grid md:grid-cols-2 md:gap-6">
                                    <div className="relative z-0 w-full mb-5 group">
                                      <input type="datetime-local" name="saleStartDate"
                                        id={`saleStartDate${index}`}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "
                                        value={input.saleStartDate ? new Date(input.saleStartDate).toISOString().slice(0, 16) : ''}
                                        onChange={event => handleFormChange(index, event)}
                                        required />
                                      <label htmlFor={`saleStartDate${index}`} className="peer-focus:font-medium absolute text-md text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                      >Date de debut de vente</label>
                                      {errors[`saleStartDate${index}`] && (
                                        <p className="text-red-600 text-sm mt-1">{errors[`saleStartDate${index}`]}</p>
                                      )}
                                    </div>
                                    <div className="relative z-0 w-full mb-5 group">
                                      <input type="datetime-local"
                                        name="saleEndDate"
                                        id={`saleEndDate${index}`}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "
                                        value={input.saleEndDate ? new Date(input.saleEndDate).toISOString().slice(0, 16) : ''}
                                        onChange={event => handleFormChange(index, event)}
                                        required />
                                      <label htmlFor={`saleEndDate${index}`} className="peer-focus:font-medium absolute text-md text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                      >Date de fin de vente</label>
                                      {errors[`saleEndDate${index}`] && (
                                        <p className="text-red-600 text-sm mt-1">{errors[`saleEndDate${index}`]}</p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="grid md:grid-cols-2 md:gap-6">
                                    <div className="relative z-0 w-full mb-5 group">
                                      <input type="text"
                                        name="standard"
                                        id={`standard${index}`}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required
                                        value={input.standard}
                                        onChange={event => handleFormChange(index, event)}
                                      />
                                      <label htmlFor={`standard${index}`} className="peer-focus:font-medium absolute text-md text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                      >Type de ticket</label>
                                      {errors[`standard${index}`] && (
                                        <p className="text-red-600 text-sm mt-1">{errors[`standard${index}`]}</p>
                                      )}
                                    </div>
                                    <div className="relative z-0 w-full mb-5 group">
                                      <input type="number"
                                        name="price"
                                        id={`price${index}`}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required
                                        value={input.price}
                                        onChange={event => handleFormChange(index, event)}
                                      />
                                      <label htmlFor={`price${index}`} className="peer-focus:font-medium absolute text-md text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                      >Prix du ticket</label>
                                      {errors[`price${index}`] && (
                                        <p className="text-red-600 text-sm mt-1">{errors[`price${index}`]}</p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="grid md:grid-cols-2 md:gap-6">
                                    <div className="relative z-0 w-full mb-5 group">
                                      <input type="number"
                                        name="availableQuantity"
                                        id={`availableQuantity${index}`}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required
                                        value={input.availableQuantity}
                                        onChange={event => handleFormChange(index, event)}
                                      />
                                      <label htmlFor={`availableQuantity${index}`} className="peer-focus:font-medium absolute text-md text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                      >Nombre de ticket disponible</label>
                                      {errors[`availableQuantity${index}`] && (
                                        <p className="text-red-600 text-sm mt-1">{errors[`availableQuantity${index}`]}</p>
                                      )}
                                    </div>
                                    <div className="relative z-0 w-full mb-5 group">
                                      <input type="datetime-local"
                                        name="ticketExpiringDate"
                                        id={`ticketExpiringDate${index}`}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required
                                        value={input.ticketExpiringDate ? new Date(input.ticketExpiringDate).toISOString().slice(0, 16) : ''}
                                        onChange={event => handleFormChange(index, event)}
                                      />
                                      <label htmlFor={`ticketExpiringDate${index}`} className="peer-focus:font-medium absolute text-md text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                      >Date d'expiration</label>
                                      {errors[`ticketExpiringDate${index}`] && (
                                        <p className="text-red-600 text-sm mt-1">{errors[`ticketExpiringDate${index}`]}</p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="grid md:grid-cols-2 md:gap-6">
                                    {/* <div className="relative z-0 w-full mb-5 group">
                                      <input type="file" name="ticketCoverUrl" id="floating_first_name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "
                                        value={input.ticketCoverUrl}
                                        onChange={event => handleFormChange(index, event)}
                                      />
                                      <label htmlFor="ticketCoverUrl" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                      >Ajouter une image</label>
                                    </div> */}
                                    <div className="relative z-0 w-full mb-5 group">
                                      <input type="number" name="numberOfPlace"
                                        id={`numberOfPlace${index}`}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required
                                        value={input.numberOfPlace}
                                        onChange={event => handleFormChange(index, event)}
                                      />
                                      <label htmlFor={`numberOfPlace${index}`} className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                      >Nombre de place par ticket</label>
                                      {errors[`numberOfPlace${index}`] && (
                                        <p className="text-red-600 text-sm mt-1">{errors[`numberOfPlace${index}`]}</p>
                                      )}
                                    </div>
                                  </div>
                                  {index !== 0 && (
                                    <button
                                      type="button"
                                      onClick={() => removeFields(index)}
                                      disabled={disabled}
                                      className={classNames(
                                        disabled ? "bg-gray-300 px-4 py-2 rounded-md cursor-not-allowed opacity-50"
                                          : "",
                                        "inline-block rounded bg-red-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                                      )}
                                    >
                                      <TrashIcon
                                        className="w-6 h-6 text-white"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  )}

                                </div>
                              )
                            })
                          }
                        </form>
                        <div className="flex md:flex-wrap justify-around">
                          <button
                            type="button"
                            onClick={() => {
                              setCurrentFormStep(currentFormStep - 1);
                              setValidateStep([...validateStep, "1"]);
                            }}
                            disabled={disabled}
                            className={classNames(
                              disabled ? "bg-gray-300 px-4 py-2 rounded-md cursor-not-allowed opacity-50"
                                : "",
                              "inline-block rounded bg-red-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                            )}
                          >
                            Précedent
                          </button>
                          <button
                            type="button"
                            onClick={addFields}
                            disabled={disabled}
                            className={classNames(
                              disabled ? "bg-gray-300 px-4 py-2 rounded-md cursor-not-allowed opacity-50"
                                : "",
                              "inline-block rounded bg-red-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                            )}
                          >
                            Ajouter ticket
                          </button>
                          <button
                            type="button"
                            onClick={onSubmitDefaultInfos}
                            disabled={disabled}
                            className={classNames(
                              disabled ? "bg-gray-300 px-4 py-2 rounded-md cursor-not-allowed opacity-50"
                                : "",
                              "inline-block rounded bg-red-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                            )}
                          >
                            Créer l'evenement
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

    </>
  );
};

export default EventPage;
