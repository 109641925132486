/* eslint-disable jsx-a11y/no-redundant-roles */
import {
  mapUserAvailableZone,
  userAvailableZonesOptions,
  UserInterface,
  UserRole,
} from "../../../interfaces";
import { useContext, useEffect, useState } from "react";
import {
  Form,
  FormInput,
  FormSelect,
} from "../../../components/FormComponents";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { db, functions } from "../../../firebase";
import { classNames, validateAndParseCoordinate } from "../../../utils";
import {
  AtSymbolIcon,
  MapIcon,
  UserPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  generatedCodeAgentCollectionsName,
  usersCollectionName,
} from "../../../config";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { UserContext } from "../../../contexts";
import { useConfirm } from "../../../hooks/useConfirm";
const mapsGender = new Map<string, string>([
  ["MALE", "Male"],
  ["FEMALE", "Female"],
  ["NONBINARY", "Non binaire"],
  ["UNKNOWN", "Inconnu"],
]);
type AdditionnalInfos = Pick<
  UserInterface,
  | "displayName"
  | "email"
  | "active"
  | "agence"
  | "city"
  | "adresse"
  | "cnibNumber"
  | "createdAt"
  | "gender"
  | "phoneNumber"
  | "profession"
  | "userRole"
  | "updatedAt"
  | "agentCode"
  | "lat"
  | "long"
  | "availableZone"
>;

const collectionName = usersCollectionName;
type Step = {
  id: "additionnalInfo" | "affiliated" | "localisation";
  index: number;
  name: string;
  description: string;
  icon: any;
};
const steps: Step[] = [
  {
    id: "additionnalInfo",
    index: 0,
    name: "Informations additionnelles",
    description: "Renseigner les informations additionnelles de l'utilisateur",
    icon: UserPlusIcon,
  },
  {
    id: "affiliated",
    index: 1,
    name: "Affilié l'agent à un dealer",
    description:
      "Renseigner les informations du dealer afin d'affilier l'utilisateur à un agent",
    icon: AtSymbolIcon,
  },
  {
    id: "localisation",
    index: 2,
    name: "Editer la localisation",
    description: "Renseigner les informations de localisation de l'agent",
    icon: MapIcon,
  },
];

const EditAgent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = location.state?.entity;
  const isAffiliated = location.state?.affiliate;
  const isMapEdit = location.state?.editLocalisation;
  const [agentCode, setAgentCode] = useState<string>();
  const [currentFormStep, setCurrentFormStep] = useState<Step>(steps[0]);
  const { user } = useContext(UserContext);
  const { isConfirmModal } = useConfirm();

  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );

  const generatedCodeAgentRef = db.collection(
    generatedCodeAgentCollectionsName
  );

  const [dealercontacts, setDealerContacts] = useState<any[]>([]);
  const userRef = db.collection(usersCollectionName);

  const getFilterdByPhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber.length >= 12) {
      await userRef
        .orderBy("createdAt", "desc")
        .where("phoneNumber", "==", phoneNumber)
        .where("userRole", "==", UserRole.DEALER)
        .get()
        .then((response) => {
          setDealerContacts(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    }
  };

  const affiliateAgentToAdealerAlerModaleConfirm = async () => {
    const isConfirm = await isConfirmModal(
      "Affilier l'agent",
      "Souhaitez-vous affilier l'agent à un distributeur ?",
      "Oui",
      true
    );

    if (isConfirm) {
      setCurrentFormStep(steps[1]);
    }
  };

  const onSubmitAdditionnalInfo = async (data: any) => {
    const toastId = toast.loading("chargement...");
    if (currentUser) {
      delete currentUser.createdAt;
      delete currentUser.updatedAt;
      delete currentUser.phoneNumber;
      const dataValue = {
        ...data,
        userRole: UserRole.AGENT,
      };
      const latitude = validateAndParseCoordinate(data.lat as string);
      const longitude = validateAndParseCoordinate(data.long as string);

      if (longitude !== null && latitude !== null) {
        dataValue.geoLocationLongitude = longitude;
        dataValue.geoLocationLatitude = latitude;
      }

      if (agentCode) {
        dataValue.agentCode = agentCode;
        await generatedCodeAgentRef.doc(agentCode).update({ isAffected: true });
      }
      if (user?.userRole === UserRole.SUPERVISOR) {
        dataValue.supervisorId = user?.id;
      }
      await sankmoneyUpdateAccountFn({
        collectionName: collectionName,
        documentData: dataValue,
        documentId: currentUser.id,
      })
        .then(() => {
          user && user?.userRole !== UserRole.SUPERVISOR
            ? affiliateAgentToAdealerAlerModaleConfirm()
            : navigate("/agents");
            toast.success("Les informations additionnel de l'agent a été enregistré avec succès.", {
              id: toastId,
          });
        })
        .catch(async(err: { message: any }) => 
          {
            toast.error(err.message, {
              id: toastId,
            });
            await generatedCodeAgentRef.doc(agentCode).update({ isAffected: false });
          }
      );
      // toast.promise(response, {
      //   error: "Une erreur s'est produite. Veuillez réessayer !",
      //   success:
      //     "Les informations additionnel de l'agent a été enregistré avec succès",
      //   loading: "chargement...",
      // });
    } else
      toast.error(
        `L'indentifiant de l'utilisateur est incorrect veuiller réessayer de nouveau !`
      );
  };

  const affiliateAgentTodealer = async (id: string) => {
    if (currentUser) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: collectionName,
        documentData: {
          dealerId: id,
        },
        documentId: currentUser.id,
      })
        .then(() => {
          navigate("/agents");
        })
        .catch((err: { message: any }) => toast.error(err.message));
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success: "L'affiliation à bien été effctué",
        loading: "Affiliation...",
      });
    } else
      toast.error(
        `L'indentifiant de l'agent est incorrect veuiller réessayer de nouveau !`
      );
  };
  const handleChange = async (e: any) => {
    const agentCode = e.target.value;
    if (agentCode.length === 6) {
      const generatedAgentCodeSnap = await generatedCodeAgentRef
        .where("isAffected", "==", false)
        .where("code", "==", agentCode)
        .get();
      if (!generatedAgentCodeSnap.empty) {
        toast.success("Le code agent que vous avez correct");
        return setAgentCode(generatedAgentCodeSnap.docs[0].id);
      } else {
        toast.error("Le code agent que vous avez entrer est indisponible");
      }
    }
  };
  const submitLocation = (data: { lat: string, long: string }) => {
    if (currentUser) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: collectionName,
        documentData: {
          id: currentUser.id,
          ...data,
        },
        documentId: currentUser.id,
      })
        .then(() => {
          navigate("/agents");
        })
        .catch((err: { message: any }) => toast.error(err.message));
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success:
          "Les informations de localisation de l'agent a été enregistré avec succès",
        loading: "chargement...",
      });
    } else
      toast.error(
        `L'indentifiant de l'agent est incorrect veuiller réessayer de nouveau !`
      );
  };

  useEffect(() => {
    currentUser && setCurrentFormStep(steps[0]);
    currentUser && isAffiliated && setCurrentFormStep(steps[1]);
    currentUser && isMapEdit && setCurrentFormStep(steps[2]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="lg:border-t lg:border-b lg:border-gray-200">
        <nav
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            <li className="relative px-2 py-4 overflow-hidden lg:py-2">
              <button
                className="p-1 border border-transparent rounded-full hover:border-gray-200 hover:bg-gray-50"
                onClick={() => navigate("/agents")}
              >
                <XMarkIcon className="w-8 h-8 text-gray-700" />
              </button>
            </li>
            {steps.map((step, stepIdx) => (
              <li key={stepIdx} className="relative overflow-hidden lg:flex-1">
                <div
                  className={classNames(
                    stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                    stepIdx === steps.length - 1
                      ? "border-t-0 rounded-b-md"
                      : "",
                    "border border-gray-200 overflow-hidden lg:border-0 h-full"
                  )}
                >
                  <button
                    className={classNames(
                      !currentUser ? "cursor-not-allowed" : "",
                      currentUser && step.index === 2 ? "" : "",
                      "w-full group h-full"
                    )}
                  >
                    <span
                      className={classNames(
                        step.index !== currentFormStep.index
                          ? "group-hover:bg-gray-200 bg-transparent "
                          : "bg-red-600",
                        "absolute top-0 left-0 w-1 h-full  lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "px-4 py-4 lg:py-2 flex items-start text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span
                          className={classNames(
                            step.index < currentFormStep.index
                              ? "bg-red-600"
                              : "",
                            step.index === currentFormStep.index
                              ? "bg-white border-2 border-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "bg-white border-2 border-gray-300"
                              : "",
                            "flex items-center justify-center w-10 h-10  rounded-full"
                          )}
                        >
                          <step.icon
                            className={classNames(
                              step.index < currentFormStep.index
                                ? "text-white"
                                : "",
                              step.index === currentFormStep.index
                                ? "text-red-600"
                                : "",
                              step.index > currentFormStep.index
                                ? "text-gray-500"
                                : "",
                              "w-6 h-6 "
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex-col flex  xl:flex">
                        <span
                          className={classNames(
                            step.index === currentFormStep.index
                              ? "text-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "text-gray-500"
                              : "",
                            "text-xs font-semibold tracking-wide uppercase md:block lg:hidden xl:block"
                          )}
                        >
                          {step.name}
                        </span>
                        <span className="flex items-start text-sm font-medium text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </span>
                  </button>
                  <div
                    className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="w-full h-full text-gray-300"
                      viewBox="0 0 12 82"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0.5 0V31L10.5 41L0.5 51V82"
                        stroke="currentcolor"
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
        {currentFormStep.id === "additionnalInfo" && (
          <Form<AdditionnalInfos>
            onSubmit={onSubmitAdditionnalInfo}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  displayName: yup
                    .string()
                    .required("Le champs est obligatoire"),
                  email: yup.string().optional(),
                  city: yup.string().optional(),
                  profession: yup.string().optional(),
                  adresse: yup.string().optional(),
                  cnibNumber: yup.string().optional(),
                  phoneNumber: yup.string().optional(),
                  agentCode: yup.string().optional(),
                  lat: yup.string().required("Veuillez saisir la latitude"),
                  long: yup.string().required("Veuillez saisir la longitude"),
                  availableZone: yup.string().optional(),
                })
              ),
              defaultValues: {
                displayName: currentUser ? currentUser.displayName : "",
                city: currentUser ? currentUser.city : "",
                adresse: currentUser ? currentUser.adresse : "",
                profession: currentUser ? currentUser.profession : "",
                phoneNumber: currentUser ? currentUser.phoneNumber : "",
                cnibNumber: currentUser ? currentUser.cnibNumber : "",
                email: currentUser ? currentUser.email : "",
                agentCode: currentUser ? currentUser.agentCode : "",
                lat: currentUser?.lat ?? "",
                long: currentUser?.long ?? "",
              },
            }}
            submitButtonLabel="Enregistrer"
            className="flex justify-center"
            isSubmitBtnDisabled={
              agentCode || currentUser.agentCode ? false : true
            }
          >
            <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
              <div className="w-full mx-auto md:w-1/2">
                <FormInput
                  name="displayName"
                  label="Nom/Prénom"
                  placeholder="John Doe"
                />
                <FormInput
                  name="city"
                  label="Ville"
                  optional
                  placeholder="Ouagadougou"
                />
                <FormInput
                  name="adresse"
                  optional
                  label="Adresse"
                  placeholder="Rue 10.34, secteur 30, Ouaga2000"
                />
                <FormInput
                  name="email"
                  label="E-mail"
                  placeholder="@exemple.com"
                  optional
                />
                <FormInput
                  name="profession"
                  label="Profession"
                  optional
                  placeholder="Agent"
                />
                <FormSelect<string>
                  label={"Genre"}
                  name="gender"
                  selectedOption={
                    currentUser ? currentUser.gender : "Choisir..."
                  }
                  options={["FEMALE", "MALE", "NONBINARY", "UNKNOWN"]}
                  optionLabel={(option) =>
                    (mapsGender.get(option) as string) ?? "Choisir..."
                  }
                />
              </div>
              <div className="w-full mx-auto md:w-1/2">
                <FormInput
                  name="phoneNumber"
                  label="Numéro de téléphone"
                  placeholder="7X XX XX XX"
                  disabled={currentUser ? true : false}
                />
                <FormInput
                  name="cnibNumber"
                  label="Numéro de CNIB"
                  placeholder="BXXXXXXX"
                />

                <FormInput
                  name="agentCode"
                  label="Saisir le code agent"
                  placeholder="Ex: 123456"
                  onChange={handleChange}
                  helpLabel={`${agentCode
                    ? ""
                    : "Veuillez entrer un code agent qui est disponible"
                    }`}
                  maxLength={6}
                />
                <FormInput
                  name="long"
                  type="text"
                  label="Longitude"
                  placeholder="Exemple: 12.002035"
                />
                <FormInput
                  name="lat"
                  type="text"
                  label="Latitude"
                  placeholder="Exemple: 02.012563"
                />
                <FormSelect<string>
                  label={"Zone"}
                  name="availableZone"
                  selectedOption={
                    currentUser ? currentUser.availableZone : "Choisir..."
                  }
                  options={userAvailableZonesOptions}
                  optionLabel={(option) =>
                    (mapUserAvailableZone.get(option) as string) ?? "Choisir..."
                  }
                  disabled={
                    currentUser && currentUser.availableZone ? true : false
                  }
                />
              </div>
            </div>
          </Form>
        )}
        {currentFormStep.id === "affiliated" && (
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="lg:border-t lg:border-b lg:border-gray-200">
              <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
                <div className="mt-3 main-form">
                  <div className="items-center justify-between ">
                    <div className="items-center justify-between flex-1 max-w-sm">
                      <PhoneInput
                        international={false}
                        placeholder="Entrer un numéro de téléphone"
                        className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                        defaultCountry="BF"
                        onChange={getFilterdByPhoneNumber}
                      />
                    </div>
                    {dealercontacts && dealercontacts.length > 0 && (
                      <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <div className="flex flex-col items-center pb-10">
                          <img
                            className="w-24 h-24 mb-3 rounded-full shadow-lg"
                            src={
                              dealercontacts[0].data.profilImageUrl
                                ? dealercontacts[0].data.profilImageUrl
                                : `https://ui-avatars.com/api/?background=random&name=${dealercontacts[0].data.displayName || "S"
                                }`
                            }
                            alt=""
                          />
                          <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                            {dealercontacts[0].data.displayName}
                          </h5>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {dealercontacts[0].data.phoneNumber}
                          </span>
                          <div className="flex mt-4 space-x-3 md:mt-6">
                            <div
                              className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                              onClick={() =>
                                affiliateAgentTodealer(dealercontacts[0].id)
                              }
                            >
                              Soumettre
                            </div>
                            <div
                              className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                              onClick={() => setDealerContacts([])}
                            >
                              Annuler
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {currentFormStep.id === "localisation" && (
          <Form<{ lat: string, long: string }>
            onSubmit={submitLocation}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  lat: yup.string().required("Veuillez saisir la latitude"),
                  long: yup.string().required("Veuillez saisir la longitude"),
                })
              ),
              defaultValues: {
                lat: currentUser?.lat ?? "",
                long: currentUser?.long ?? "",
              },
            }}
            submitButtonLabel="Enregistrer"
            className="flex justify-center"
          >
            <FormInput
              name="long"
              type="number"
              label="Longitude"
              placeholder="Exemple: 12.002035"
            />
            <FormInput
              name="lat"
              type="number"
              label="Latitude"
              placeholder="Exemple: 02.012563"
            />
          </Form>
        )}
      </div>
    </>
  );
};

export default EditAgent;
